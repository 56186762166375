export const removeURLParameter = (url, parameter) => {
  //prefer to use l.search if you have a location/link object
  let prefix = encodeURIComponent(parameter) + "=";
  let pars = url.split(/[&;]/g);

  //reverse iteration as may be destructive
  for (let i = pars.length; i-- > 0; ) {
    //idiom for string.startsWith
    if (pars[i].indexOf(prefix) === -1) {
      pars.splice(i, 0);
    } else {
      pars.splice(i, 1);
    }
  }

  url = pars.join("&");

  if (url[0] !== "?") {
    url = "?" + url;
  }
  return url;
};
