// import AirbrakeClient from "airbrake-js";
import React from "react";
import ErrorFallback from "./ErrorFallback";

class AirBrake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      isAN2: window.location.href.includes("/n/apply"),
      error: null,
      errorId: "",
    };
    // let env = "";
    // if (window.PLAY_ENV && window.PLAY_ENV !== "") {
    //   env = window.PLAY_ENV;
    // } else {
    //   if (
    //     window.location.origin.includes(":9000") ||
    //     window.location.origin.includes(":3000") ||
    //     window.location.host === "localhost"
    //   ) {
    //     env = "localhost";
    //   }
    // }
    // if (env !== "localhost" && env !== "develop") {
    // this.airbrake = new AirbrakeClient({
    //   projectId: 221995,
    //   projectKey: "1d91df6dba99c45fb20c3f5d191dd49a",
    //   environment: env,
    // });

    // this.airbrake.addFilter((notice) => {
    //   if (
    //     notice.error &&
    //     notice.error === "Registration failed - push service error"
    //   ) {
    //     //ignore this error generated by the airbrake code itself
    //     return null;
    //   }
    //   if (
    //     notice.error &&
    //     notice.error.includes("Extension context invalidated")
    //   ) {
    //     //ignore this error, its from a chrome extension
    //     return null;
    //   }
    //   if (notice.context && notice.context.sourceMapErrors) {
    //     let keys = Object.keys(notice.context.sourceMapErrors);
    //     if (keys.length > 0) {
    //       let chromeExtension = false;
    //       keys.map((i) => {
    //         if (i.toLowerCase().includes("chrome-extension")) {
    //           chromeExtension = true;
    //         }
    //         let value = notice.context.sourceMapErrors[i];
    //         if (value && value.toLowerCase().includes("chrome-extension")) {
    //           chromeExtension = true;
    //         }
    //       });
    //       if (chromeExtension) {
    //         return null;
    //       }
    //     }
    //   }
    //   return notice;
    // });
    // }
  }

  componentDidCatch(error, info) {
    // if (this.airbrake) {
    // Display fallback UI
    this.setState({ hasError: true, error });

    // Format error data and pass to the server for logging
    const errorStackFrames = error.stack
      .split("\n")
      .map((line) => {
        const match = line.match(/at (.*) \((.*):(\d+):(\d+)\)/);
        if (match) {
          return {
            functionName: match[1].trim(),
            source: match[2].trim(),
            line: parseInt(match[3], 10),
            column: parseInt(match[4], 10),
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);
    const componentStackFrames = info.componentStack
      .split("\n")
      .map((line) => {
        const match = line.match(/at (.*) \((.*):(\d+):(\d+)\)/);
        if (match) {
          return {
            componentName: match[1].trim(),
            source: match[2].trim(),
            line: parseInt(match[3], 10),
            column: parseInt(match[4], 10),
          };
        } else {
          return null;
        }
      })
      .filter(Boolean);

    const errorData = {
      name: error.name,
      message: error.message,
      stack: errorStackFrames,
      info: componentStackFrames,
      windowLocation: window.location.href,
      prospectId: localStorage.getItem("PROSPECT_ID"),
      sessionId: localStorage.getItem("SESSION_ID"),
    };
    fetch("/browser-error", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "csrf-token": window.AN2_CSRF,
      },
      body: JSON.stringify(errorData),
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        }
      })
      .then((text) => {
        this.setState({ errorId: text });
      });
    // Send error to Airbrake
    // this.airbrake.notify({
    //   error: error,
    //   params: { info: info },
    // });
    // }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback errorId={this.state.errorId} />;
    }
    return this.props.children;
  }
}

export default AirBrake;
