export const setSidebarLink = link => ({
    type: "SET_CURRENT_SIDEBAR_LINK", payload: link
});

export const setLOConfig = config => ({
    type: "SET_LO_CONFIG", payload: config
});

export const setCSRF = token => ({
    type: "SET_CSRF", payload: token
});

export const setUserInfo = user => ({
    type: "SET_USER_INFO", payload: user
});

export const setAllSavedFormValues = values => ({
    type: "SET_ALL_SAVED_FORM_VALUES", payload: values
});

export const setNewFormValues = values => ({
    type: "SET_NEW_FORM_VALUES", payload: values
});

export const setEditPersonalInfo = values => ({
    type: "SET_EDIT_PERSONAL_INFO", payload: values
});

export const setTeamLogo = logo => ({
    type: "SET_TEAM_LOGO", payload: logo
});

export const setIsRegistered = bool => ({
    type: "SET_IS_REGISTERED", payload: bool
});

export const setNotifySent = bool => ({
    type: 'SET_NOTIFY_SENT', payload: bool
})

export const setShowLoading = bool => ({
    type: "SET_SHOW_LOADING", payload: bool
});

export const setShowLogoutErrorModal = bool => ({
    type: "SET_SHOW_LOGOUT_ERROR_MODAL", payload: bool
});

export const setShowLoadingNote = values => ({
    type: "SET_SHOW_LOADING_NOTE", payload: values
});

export const setShowFinalModal = bool => ({
    type: "SET_SHOW_FINAL_MODAL", payload: bool
});

export const setProfileMenuViz = bool => ({
    type: "SET_PROFILE_MENU_VIZ", payload: bool
});

export const setMobileMenuViz = bool => ({
    type: "SET_MOBILE_MENU_VIZ", payload: bool
});

export const setCheckingManualComplete = bool => ({
    type: "SET_CHECKING_MANUAL_COMPLETE", payload: bool
});

export const setFormFreeConsentCobo = bool => ({
    type: "SET_FORM_FREE_CONSENT_COBO", payload: bool
});

export const setFormFreeConsentBo = bool => ({
    type: "SET_FORM_FREE_CONSENT_BO", payload: bool
});


export const setReRenderSidebar = bool => ({
    type: "SET_RE_RENDER_SIDEBAR", payload: bool
});
export const setShowConvertedModal = bool => ({
    type: 'SET_SHOW_CONVERTED_MODAL', payload: bool
})

// UPDATE SAVED ERRORS
export const setAllSavedErrors = errors => ({
    type: "SET_ALL_SAVED_ERRORS", payload: errors
});

export const setAboutYouStartErrors = errors => ({
    type: "SET_ABOUT_YOU_START_ERRORS", payload: errors
});

export const setAboutYouCBErrors = errors => ({
    type: 'SET_ABOUT_YOU_CB_ERRORS', payload: errors
});

export const setAboutYouCBStartErrors = errors => ({
    type: 'SET_ABOUT_YOU_CB_START_ERRORS', payload: errors
});

export const setYourGoalsRefiErrors = errors => ({
    type: 'SET_YOUR_GOALS_REFI_ERRORS', payload: errors
});

export const setYourGoalsPurchaseErrors = errors => ({
    type: 'SET_YOUR_GOALS_PURCHASE_ERRORS', payload: errors
});

export const setDemographicsErrors = errors => ({
    type: 'SET_DEMOGRAPHICS_ERRORS', payload: errors
});

export const setCBDemographicsErrors = errors => ({
    type: 'SET_CB_DEMOGRAPHICS_ERRORS', payload: errors
});

export const setDeclarationsErrors = errors => ({
    type: 'SET_DECLARATIONS_ERRORS', payload: errors
});

export const setCBDeclarationsErrors = errors => ({
    type: 'SET_CB_DECLARATIONS_ERRORS', payload: errors
});