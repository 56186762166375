import React, { useReducer } from "react";
import { StateStoreContext } from "./StateStoreContext";
import { UiStateContext } from "./UiStateContext";
import { LanguageContext } from "./LanguageContext";
import { SubtitleContext } from "./SubtitleContext";
import { CoPilotContext } from "./CoPilotContext";

import { skeleton } from "./initialSavedFormValues";
import savedFormValuesReducer from "./savedFormValuesReducer";

import { initialTenOhThreeValues } from "./initialTenOhThreeValues";
import tenOhThreeReducer from "./tenOhThreeReducer";

import { initialLanguageText } from "./initialLanguageText";
import languageTextReducer from "./languageTextReducer";

import { initialSubtitleText } from "./initialSubtitleText";
import subtitleTextReducer from "./subtitleTextReducer";

import coPilotReducer from "./coPilotReducer";

// const StateStoreContext = React.createContext();

// function useFormReducer ( reducer, initialState ){
//     return React.useReducer( produce(reducer), initialState)
// }

const formReducer = savedFormValuesReducer;
const uiReducer = tenOhThreeReducer;
const languageText = languageTextReducer;

const StateStore = (props) => {
  // const [ formValues, dispatch ] = useReducer( formReducer, initialSavedFormValues )
  const [formValues, dispatch] = useReducer(formReducer, {});
  const [uiValues, uiDispatch] = useReducer(uiReducer, initialTenOhThreeValues);
  const [languageText, languageDispatch] = useReducer(
    languageTextReducer,
    initialLanguageText
  );
  const [subtitleText, subtitleDispatch] = useReducer(
    subtitleTextReducer,
    initialSubtitleText
  );
  const [coPilot, coPilotDispatch] = useReducer(coPilotReducer, {});

  // state = {
  //     // values
  //     savedFormValues : initialSavedFormValues,
  //     tenOhThree : initialTenOhThreeValues,

  //     //actions
  //     actions : {
  //         savedFormValues : savedFormValuesActions,
  //         tenOhThree : tenOhThreeActions
  //     }
  // };

  return (
    <StateStoreContext.Provider value={{ formValues, dispatch }}>
      <UiStateContext.Provider value={{ uiValues, uiDispatch }}>
        <LanguageContext.Provider value={{ languageText, languageDispatch }}>
          <SubtitleContext.Provider value={{ subtitleText, subtitleDispatch }}>
            <CoPilotContext.Provider value={{ coPilot, coPilotDispatch }}>
              {props.children}
            </CoPilotContext.Provider>
          </SubtitleContext.Provider>
        </LanguageContext.Provider>
      </UiStateContext.Provider>
    </StateStoreContext.Provider>
  );
};
export default StateStore;
