// import { initialTenOhThreeValues } from './initialTenOhThreeValues'
import _ from "lodash";

const coPilotReducer = (state, action) => {
  switch (action.type) {
    case "CO_PILOT_LOCAL_USER_ENTER":
      return {
        ...state,
        localUser: action.payload,
      };
    case "CO_PILOT_LOCAL_USER_INPUT_CHANGE":
      return {
        ...state,
        localUser: {
          ...state.localUser,
          dataMap: action.payload,
        },
      };
    case "CO_PILOT_LOCAL_USER_ROUTE_CHANGE":
      return {
        ...state,
        localUser: {
          ...state.localUser,
          route: action.payload,
        },
      };
    case "CO_PILOT_LOCAL_USER_NAME_CHANGE":
      return {
        ...state,
        localUser: {
          ...state.localUser,
          userName: action.payload,
        },
      };

    // copilot foreign user actions
    case "CO_PILOT_FOREIGN_USER_ENTER":
      let foreignUserEnter = _.cloneDeep(state.foreignUsers);
      let addForeignUser = [];
      if (foreignUserEnter) {
        addForeignUser = [...foreignUserEnter];
        let found = addForeignUser.find(
          (user) => user.sourceSession === action.payload.sourceSession
        );
        if (!found) {
          addForeignUser.push(action.payload);
        }
      } else {
        addForeignUser.push(action.payload);
      }
      return {
        ...state,
        foreignUsers: [...addForeignUser],
      };
    case "CO_PILOT_FOREIGN_USER_EXIT":
      let foreignUserExit = _.cloneDeep(state.foreignUsers);
      let removeForeignUser = [];
      if (foreignUserExit) {
        removeForeignUser = foreignUserExit.filter(
          (user) => user.sourceSession !== action.payload
        );
      }
      return {
        ...state,
        foreignUsers: [...removeForeignUser],
      };
    case "CO_PILOT_FOREIGN_USER_INPUT_CHANGE":
      let foreignUserInputChange = _.cloneDeep(state.foreignUsers);
      if (foreignUserInputChange) {
        let newInput = foreignUserInputChange.map((item) => {
          let temp = Object.assign({}, item);
          if (temp.sourceSession === action.payload.sourceSession) {
            temp.dataMap = action.payload.dataMap;
          }
          return temp;
        });
        return {
          ...state,
          foreignUsers: [...newInput],
        };
      } else {
        return state;
      }
    case "CO_PILOT_FOREIGN_USER_ROUTE_CHANGE":
      let foreignUserRouteChange = _.cloneDeep(state.foreignUsers);
      if (foreignUserRouteChange) {
        let newRoute = foreignUserRouteChange.map((item) => {
          let temp = Object.assign({}, item);
          console.log(action.payload);
          if (temp.sourceSession === action.payload.sourceSession) {
            temp.route = action.payload.route;
            temp.listInfo = action.payload.listInfo;
          }
          return temp;
        });
        return {
          ...state,
          foreignUsers: [...newRoute],
        };
      } else {
        return state;
      }
    case "CO_PILOT_FOREIGN_USER_PING":
      let foreignUserPing = _.cloneDeep(state.foreignUsers);
      let newUserInfo = [];
      if (foreignUserPing) {
        let found = false;
        newUserInfo = foreignUserPing.map((user) => {
          if (user.sourceSession === action.payload.sourceSession) {
            found = true;
            let updatedUser = {
              ...user,
              ...action.payload.userInfo,
            };
            console.log("1");
            return updatedUser;
          } else {
            return user;
          }
        });
        if (!found) {
          // add them if not there
          console.log("2");
          newUserInfo = [...foreignUserPing];
          newUserInfo.push({
            ...action.payload.userInfo,
            sourceSession: action.payload.sourceSession,
          });
        }
        console.log(newUserInfo);
        return {
          ...state,
          foreignUsers: [...newUserInfo],
        };
      } else {
        let brandNewUser = {
          ...action.payload.userInfo,
          sourceSession: action.payload.sourceSession,
        };
        return {
          ...state,
          foreignUsers: [brandNewUser],
        };
      }
    case "CO_PILOT_FOREIGN_USER_NAME_CHANGE":
      let foreignUserNameUpdate = _.cloneDeep(state.foreignUsers);
      if (foreignUserNameUpdate) {
        foreignUserNameUpdate = foreignUserNameUpdate.map((user) => {
          if (user.sourceSession === action.payload.sourceSession) {
            user.userName = action.payload.userName;
          }
          return user;
        });
      } else {
        //this should never get hit
      }

      return {
        ...state,
        foreignUsers: [...foreignUserNameUpdate],
      };
    default:
      return state;
  }
};

export default coPilotReducer;
