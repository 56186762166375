export const initialMortgageCalcValues = {

  mortgageCalc: [
    {
      name: 'Home Price',
      value: 200000,
      edited: false
    },
    {
      name: 'Down Payment',
      value: 20000,
      edited: false
    },
    {
      name: 'Down Payment Percent',
      value: 10,
      edited: false
    },
    {
      name: 'Loan Amount',
      value: 180000,
      edited: false
    },
  ],
}
