import { initialLanguageText } from "./initialLanguageText";

const languageTextReducer = (state = initialLanguageText, action) => {
  switch (action.type) {
    case "SAVE_ALL":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default languageTextReducer;
