import { styled } from "@mui/material";
import React from "react";

export default function ErrorFallback({ errorId }) {
  return (
    <ErrorFallbackWrapper>
      {/* <!--Error Block--> */}
      <div class="error-500-container">
        <div class="column">
          <h1 class="header-text">Oops, something went wrong. </h1>
          <p class="caption-text-2">We have been notified of the issue.</p>
          {errorId && <p class="error-text">Error ID: {errorId}</p>}
        </div>

        <img
          src="https://floify-assets.s3.amazonaws.com/images/503.gif"
          alt="503"
        />
      </div>

      {/* <!--=== Copyright ===--> */}
      <div role="contentinfo" class="copyright">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <p>
                <span id="year">
                  &copy; {new Date().getFullYear()} Floify LLC
                </span>
                <a href="https://floify.com/terms">Privacy &amp; Terms</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </ErrorFallbackWrapper>
  );
}

const ErrorFallbackWrapper = styled("div")`
  background: #fff;
  display: grid;
  grid-template-rows: 1fr auto;
  width: 100%;
  height: 100%;
  padding: 145px 24px 76px;

  a {
    color: #0071f3;
  }

  a:hover {
    color: #0f4dc3;
  }

  .content {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .error-500-container {
    font-family: "proxima-nova" !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .error-500-container .column {
    display: flex;
    flex-direction: column;
  }

  .error-500-container .row {
    display: flex;
    flex-direction: row;
  }

  .error-500-container .header-text {
    font-family: "proxima-nova" !important;
    font-size: 60px !important;
    line-height: 72px;
    margin-bottom: 24px;
    margin-top: 24px;
    max-width: 480px;
    font-weight: 600;
    color: #0f172a !important;
  }

  .error-500-container a {
    color: #0071f3;
    transition: 0.2s;
    text-decoration: underline;
  }

  .error-500-container a:hover {
    color: #0f4dc3;
  }

  .error-500-container .caption-text {
    color: #5a7281;
    font-size: 18px;
    font-weight: bold;
    margin-top: 0;
    margin-bottom: 0;
  }

  .error-500-container .caption-text-2 {
    color: #6b7280;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .error-500-container .error-text {
    color: #98a2b3;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 24px;
  }

  .error-500-container img {
    height: 320px;
    width: 320px;
    margin-left: 62px;
  }

  .copyright {
    font-size: 12px;
    padding: 11px 0 7px;
    text-align: center;
  }

  .copyright p {
    color: #333;
    margin-top: 12px;
  }

  .copyright a {
    margin: 0 5px;
  }

  @media (max-width: 700px) {
    padding-top: 0;
    padding-bottom: 0;

    .error-500-container {
      flex-direction: column-reverse;
    }

    .error-500-container .header-text {
      font-size: 40px !important;
      line-height: 48px;
    }

    .error-500-container .caption-text-2 {
      font-weight: normal;
    }

    .error-500-container img {
      height: 200px;
      width: 200px;
      margin: 0;
    }
  }
`;
