export const English = {
  inputs: {
    yes: "Yes",
    no: "No",
    requiredInfo: "Required Information",
    unsupportedCharError:
      "Please complete your application using only letters from A-Z and numbers from 0-10. We apologize for any inconvenience.",
    startApplication: "Start Application",
    startSection: "Start Section",
    finishSection: "Finish Section",
    continue: "Continue",
    firstName: "First Name",
    preferredName: "Preferred Name",
    middleName: "Middle Name",
    lastName: "Last Name",
    legalFirstName: "Legal First Name",
    legalMiddleName: "Legal Middle Name",
    legalLastName: "Legal Last Name",
    suffix: "Suffix (Jr., Sr., etc.)",
    suffixShort: "Suffix",
    alternateFirstName: "Alternate First Name",
    alternateMiddleName: "Alternate Middle Name",
    alternateLastName: "Alternate Last Name",
    alternateSuffix: "Alternate Suffix",
    alternateNames: "Alternate Names",
    dob: "Date of Birth",
    maritalStatus: "Marital Status",
    education: "Highest Level of Education Completed",
    domesticRelationshipType: "Relationship Type",
    domesticRelationshipStateCode: "State in which relationship was formed",
    ssn: "Social Security Number",
    citizenship: "U.S. Citizenship Status",
    doesNotApply: "Does not apply",
    hasMilitary: "Has Military Service",
    military: "Military Service",
    projectedExpiration: "Projected Expiration Date",
    email: "Email",
    phone: "Phone",
    homePhone: "Home Phone",
    mobilePhone: "Mobile Phone",
    workPhone: "Work Phone",
    ext: "Ext",
    numberDependents: "Number of Dependents",
    ageDependents: "Age of Dependents",
    currentAddress: "Address",
    addressRequired: "Address Required",
    street: "Street Address",
    streetRequired: "Street Address Required",
    unit: "Unit #",
    city: "City",
    preferredCity: "Preferred City",
    cityRequired: "City Required",
    state: "State",
    preferredState: "Preferred State",
    stateRequired: "State Required",
    postalCode: "Postal Code",
    county: "County",
    postalCodeRequired: "Postal Code Required",
    country: "Country",
    countryRequired: "Country Required",
    moveIn: "Move In Date",
    moveInRequired: "Move In Date Required",
    moveOut: "Move Out Date",
    moveOutRequired: "Move Out Date Required",
    ownRent: "Own or Rent?",
    rentMonth: "Rent Per Month",
    mortgageMonth: "Mortgage per Month",
    otherCostsMonth: "Total Other Costs per Month",
    totalMortgageLiens: "Total Mortgage and Liens Due",
    sameAsCurrent: "Same as Current Address",
    mailing: "Mailing Address",
    formerAddress: "Former Address",
    addFormerAddress: "Add Former Address",
    creditorName: "Creditor Name",
    lienType: "Lien Type",
    prepaidItemsEstimatedAmount: "Estimated Prepaid Items",
    monthlyPayment: "Monthly Payment",
    monthlyPrincipalAndInterestPayment: "Monthly Principal & Interest",
    monthlyOtherPayment: "Monthly Insurance, Taxes, Association Dues, etc.",
    fundsSourceType: "Funds Source Type (only visible to LO teams)",
    ifNotIncludedInYourMonthlyPrincipalAndInterest:
      "(If Not Included in Your Monthly Principal & Interest)",
    projectedReservesAmount: " Projected Reserves Amount",
    // loanAmount : 'Loan Amount/To Be Drawn',
    loanAmountShort: "Loan Amount",
    rentalIncome: "Expected Monthly Rental Income",
    rentalIncomeShort: "Rental Income",
    giftGrant: "Gift/Grant Type",
    deposited: "Deposited/Not Deposited",
    depositedShort: "Deposited",
    source: "Source",
    cashMarket: "Cash or Market Value",
    employerName: "Employer/Business Name",
    jobTitle: "Job Title",
    employerPhone: "Employer/Business Phone",
    employerAddress: "Employer/Business Address",
    employedByParty: "Employed by a party to the transaction?",
    ownership: "Ownership",
    ownershipShare: "Ownership Share",
    ownershipShareShort: "Ownership Share",
    monthlyIncome: "Monthly Income",
    monthlyLoss: "Monthly Loss",
    monthlyIncomeShort: "Monthly Income/Loss",
    startDate: "Start Date",
    endDate: "End Date",
    experienceYears: "Years Employed in this Profession (Not Just this Job)",
    experienceYearsShort: "Experience Years",
    experienceMonths: "Months Employed in this Profession (Not Just this Job)",
    experienceMonthsShort: "Experience Months",
    experience: "Experience",
    grossMonthly: "Gross Monthly Income",
    total: "TOTAL",
    perMonth: "/month",
    monthlyAmount: "Monthly Amount",
    base: "Base",
    baseIncome: "Base Income",
    baseMonthlyIncome: "Monthly Pay Before Taxes",
    overtime: "Overtime",
    overtimeIncome: "Overtime Income",
    bonus: "Bonus",
    bonusPlural: "Bonuses",
    bonusIncome: "Bonus Income",
    commission: "Commission",
    commissionIncome: "Commission Income",
    militaryEntitlements: "Military Entitlements",
    other: "Other",
    otherIncome: "Other Income",
    accounts: "Accounts",
    delete: "Delete",
    assetType: "Type of Asset",
    selectOne: "Select One",
    selectState: "Select State",
    selectAll: "Select all that apply to you.",
    selectProjectTypes: "Select the project types that apply.",
    liabilityType: "Type of Liability",
    accountNumber: "Account Number",
    toBePaidOff: "To be paid off at/before closing",
    excludeLiability:
      "Exclude this liability from loan calculations (only visible to LO teams)",
    unpaidBalance: "Unpaid Balance",
    companyName: "Company Name",
    payeeName: "Payee Name",
    otherDescription: "Description",
    isCurrentAddress: "Is this your current address?",
    financialInstitution: "Financial Institution",
    institutionName: "Institution Name",
    cashValue: "Cash or Market Value",
    propertyValue: "Property Value",
    estimatedPropertyValue: "Estimated Property Value",
    purchaseEstimatedPropertyValue:
      "Estimated Property Value (only visible to LO Teams)",
    status: "Status",
    value: "Value",
    monthlyRentalIncome: "Monthly Rental Income",
    monthlyRentalIncomeOrLoss:
      "Do you receive monthly rental income or loss? (only visible to LO Teams)",
    monthlyNetRentalIncome: "Net Rental Income (only visible to LO Teams)",
    monthlyNetRentalLoss: "Net Rental Loss (only visible to LO Teams)",
    isInvestmentProperty: "Is this an investment property?",
    netMonthlyRental: "Net Monthly Rental Income",
    monthlyExpenses: "Monthly Insurance, Taxes, Association Dues, etc.",
    ifNotIncludedInYourMonthlyMortgagePayment:
      "(If Not Included in Your Monthly Mortgage Payment)",
    monthlyHomeownersInsurance: "Monthly Homeowners Insurance",
    monthlyRealEstateTaxes: "Monthly Real Estate Taxes",
    monthlyHoaDues: "Monthly Homeowners Associate Dues",
    supplementalPropertyInsurance: "Supplemental Property Insurance",
    doNotIncludeInsurance:
      "Do not include fees included in monthly mortgage payment.",
    mortgageType: "Type of Mortgage",
    monthlyMortgage: "Monthly Mortgage Payment",
    mortgageHelp: "Help: Include principle and interest in the monthly amount.",
    creditLimit: "Credit Limit (if applicable)",
    propertyAddress: "Property Address",
    numberOfUnits: "Number Of Units",
    desiredNumberOfUnits: "Desired Number of Units",
    attachmentType: "Property Attachment Type (only visible to LO Teams)",
    numberOfUnitsOther: "How many units?",
    propertyType: "Type Of Property",
    desiredPropertyType: "Desired Type of Property",
    occupancy: "Occupancy",
    mixedUse: "Is the property mixed-use?",
    mixedUseTbd: "Will this property be mixed-use?",
    mixedUseShort: "Mixed-Use",
    manufactured: "Is the property a manufactured home?",
    manufacturedShort: "Manufactured Home",
    manufacturedHomeWidthType:
      "Manufactured Home Width Type (only visible to LO Teams)",
    loanPurpose: "Loan Purpose",
    loanType: "Loan Type",
    purchasePrice: "Purchase Price",
    desiredPurchasePrice: "Desired Purchase Price",
    downPayment: "Down Payment",
    preferredDownPayment: "Preferred Down Payment",
    loanAmount: "Desired Loan Amount",
    propertyUse: "Use of Property",
    paymentSource: "Source of Down Payment",
    address: "Address",
    employment: "Employment",
    name: "Name",
    amount: "Amount",
    primary_residence: "Primary Residence",
    hasRelationship: "Has Relationship With Seller",
    undisclosedFunds: "Has Undisclosed Funding",
    undisclosedFundsAmount: "Undisclosed Funding Amount",
    undisclosedLoans: "Has Undisclosed Loans",
    undisclosedCredit: "Has Undisclosed Credit",
    hasPriorityLien: "Subject to Priority Lien",
    undisclosedCosign: "Co-signer to Undisclosed Debt",
    outstandingJudgements: "Has Outstanding Judgements",
    delinquentDebt: "Has Delinquent Debt",
    partyLawsuit: "Is Party to a Lawsuit",
    conveyedTitle: "Has Conveyed Title",
    completedShortSale: "Has Completed Short Sale",
    forclosedProperty: "Has Foreclosured Property",
    declaredBankruptcy: "Has Declared Bankruptcy",
    bankruptcyType: "Bankruptcy Type",
    edit: "Edit",
    type: "Type",
    close: "Close",
    due: "Due",
    otherCosts: "Other Costs",
    realtorName: "Realtor's Full Name",
    realtorEmail: "Realtor's Email",
    areYouSure: "Are you sure?",
    selfEmployed: "Self Employed/Owner",
    currentlyWorkHere: "I currently work here",
    coboCurrentlyWorkHere: "Currently works here",
    combat_pay: "Military Combat Pay",
    flight_pay: "Military Flight Pay",
    hazard_pay: "Military Hazard Pay",
    overseas_pay: "Military Overseas Pay",
    prop_pay: "Military Prop Pay",
    clothes_allowance: "Clothes Allowance",
    rations_allowance: "Rations Allowance",
    variable_housing_allowance: "Variable Housing Allowance",
    quarters_allowance: "Quarters Allowance",
    alimony: "Alimony",
    child_support: "Child Support",
    alimonyChildSupport: "Alimony/Child Support",
    alimonyChildSupportLong: "Alimony or Child Support?",
    automobile_allowance: "Automobile Allowance",
    boarder_income: "Boarder Income",
    capital_gains: "Capital Gains",
    disability: "Disability",
    foster_care: "Foster Care",
    housing_or_parsonage: "Housing or Parsonage",
    interest_and_dividends: "Interest and Dividends",
    mortgage_credit_certificate: "Mortgage Credit Certificate",
    mortgage_differential_payments: "Mortgage Differential Payments",
    notes_receivable: "Notes Receivable",
    public_assistance: "Public Assistance",
    retirement: "Retirement",
    royalty_payments: "Royalty Payments",
    separate_maintenance: "Separate Maintenance",
    social_security: "Social Security",
    trust: "Trust",
    unemployment_benefits: "Unemployment Benefits",
    va_compensation: "VA Compensation",
    none: "None",
    fha_secondary_residence: "FHA Secondary Residence",
    second_home: "Second Home",
    investment_property: "Investment Property",
    sole: "By yourself",
    jointly_with_spouse: "With your spouse",
    jointly_with_other: "Jointly with another person",
    explanation: "Explanation",
    married: "Marriedxxx",
    separated: "Separated",
    unmarried: "Unmarried",
    purchaseState: "In what state are you looking to buy?",
    refiState: "In what state are you looking to refinance?",
    otherState: "In what state are you looking to use this loan?",
    payment: "Payment",
    specifyPurpose: "Specify Loan Purpose",
    borrowerOrCoborrower:
      "Does this belong to you, your co-borrower, or both of you?",
    an3BorrowerOrCoborrower:
      "Does this belong to you, your co-borrower, or both of you?",
    directIndirectLoan: "Directly or indirectly obligated on foreclosed loan",
    borrowedDownPayment: "Borrowed down payment",
    coMakerNote: "Co-maker or endorser on note",
    ownershipInterest: "Ownership interest in property",
    otherFinancing: "Do you have other financing for this property?",
    hazardInsurance: "Do you have hazard insurance for this property?",
    mortgageInsurance: "Do you have mortgage insurance for this property?",
    realEstateTaxes: "Do you pay real estate taxes on this property?",
    hoaDues: "Do you pay homeowner association dues on this property?",
    otherMonthlyExpenses:
      "Do you incur any other monthly expenses for this property?",
    monthsLeft: "Months Left to Pay",
    incomeOrLoss: "Do you receive monthly income or loss?",
    monthlyNetRentalIncomeOrLoss:
      "Do you receive monthly rental income or loss? (only visible to LO Teams)?",
    realtorPermission: "Realtor Permission",
    lookingIn: "Looking in",
    employmentAddressLengthError: "Street address must be under 35 characters.",
    haveMortgage: "Do you have a mortgage on this property?",
    otherFinancingLabel: "Other Financing per Month",
    hazardInsuranceLabel: "Hazard Insurance per Month",
    mortgageInsuranceLabel: "Mortgage Insurance per Month",
    realEstateTaxesLabel: "Real Estate Taxes per Month",
    hoaDuesLabel: "HOA Dues per Month",
    otherMonthlyExpensesLabel: "Other Expenses per Month",
    isSubjectPropertyLoan:
      "Is this the subject property for this loan? (only visible to LO teams)",
    subjectLoanResubordinationIndicator:
      "Will this be subordinated to the subject property? (only visible to LO Teams)",
  },
  global: {
    review: "Review",
    colon: ": ",
    comma: ", ",
    na: "N/A",
  },
  welcome: {
    heading: "Welcome From",
    intro:
      "In the application we’ll learn more about you and what kind of loan you’re looking for. Let’s get started!",
  },
  register: {
    heading: "Register",
    subHeading: "Please complete your registration before beginning.",
    termsAndAgreementsTitle: "Terms and Agreements",
    termsAndAgreementsSubtitle:
      "The following consents are required to complete your loan.",
    consentText: "I consent to",
    eConsentLinkText: "do business electronically",
    tcpaConsentHeader:
      "assistance on key services needed for my loan, such as securing homeowners insurance",
    learnMore: "Learn more.",
    textTitle: "Updates from Your Lender",
    textOptInText:
      "receive text messages from my lender for status updates on my loan",
    textConsentOne:
      "SMS message service subscribers can: opt in to the SMS service in the Floify 1003 Web Application or on the Account Settings page; opt out of the SMS service on the Account Settings page or by replying with the keyword ",
    textConsentTwo: "“STOP”",
    textConsentThree:
      " to the message received; receive assistance with the SMS service by replying with the keyword ",
    textConsentFour: "“HELP”",
    textConsentFive:
      " to the message received. You may receive up to five SMS messages per week, occasionally more. Standard message and data rates may apply. ",
    textConsentSix: "For more information, visit the ",
    textConsentSeven: "Terms of Use and Privacy Policy",
  },
  borrowerIntro: {
    borrowerHeading: "About You",
    borrowerSubHeading:
      "Let’s get started by learning a little more about you.",
    coBorrowerHeading: "About Your Co-Borrower",
    coBorrowerSubHeading:
      "Let us know if you’re applying with a co-borrower, and provide us with some information about them.",
  },
  addCoBorrower: {
    heading: "Add Co-Borrower",
    subHeading: "Let us know if you’re applying with a co-borrower.",
    questionOne: "Are you applying with a co-borrower?",
    questionTwo: "Does your co-borrower live with you?",
  },
  personalInfo: {
    heading: "Personal Info",
    borrowerSubHeading:
      "Let’s get started by learning a little more about you.",
    coBorrowerSubHeading:
      "Now let’s learn a little more about your co-borrower.",
    militaryQuestion:
      "Did you (or your deceased spouse) ever serve, or are you currently serving, in the United States Armed Forces?",
    alternateNameQuestion: "Do you have any alternate names?",
    addAlternateName: "Add Alternate Name",
    domesticRelationshipIndicator:
      "Is there a person who is not your legal spouse but who currently has real property rights similar to those of a legal spouse?",
  },
  contactInfo: {
    heading: "Contact Info",
    borrowerSubHeading: "Let us know how we can get in touch with you.",
    coBorrowerSubHeading:
      "Let us know how we can get in touch with your co-borrower.",
  },
  supplementalConsumerInfo: {
    heading: "Supplemental Info",
    borrowerSubHeading: "Let us know how we can help you find your home.",
    coBorrowerSubHeading: "Let us know how we can help you find your home.",
  },
  dependents: {
    heading: "Dependents",
    borrowerQuestion: "Do you have any dependents?",
    coBorrowerQuestion:
      "Does your co-borrower have dependents other than those entered in Your Info?",
    ageOfDependent: "Age of Dependent",
    ageOfDependentRequired: "Age of Dependent is required",
  },
  currentAddress: {
    heading: "Current Address",
    subHeading:
      "Please give us some information about where you currently live.",
  },
  addressHistory: {
    heading: "Address History",
    borrowerRequiredSubHeading:
      "Please provide 2 years of address history — everywhere you’ve lived since",
    borrowerNotRequiredSubHeading: "Please provide your address history.",
    coBorrowerRequiredSubHeading:
      "Please provide 2 years of address history for your co-borrower — everywhere they’ve lived since",
    coBorrowerNotRequiredSubHeading:
      "Please provide your co-borrower’s address history.",
    mailingAddressQuestion: "Do you have a separate mailing address?",
    coboMailingAddressQuestion:
      "Does your co-borrower have a separate mailing address?",
    addFormerAddress: "Add Former Address",
    addressHistoryRequired: "At least 2 years of address history is required.",
    rentalIncomePerMonth: "Do you earn rental income on this property?",
  },
  addressHistoryOverview: {
    heading: "Address History Overview",
    subHeading: "Do you have any more addresses to include?",
    currentRequired: "Your current address is required",
    twoYearsRequired: "At least 2 years of address history is required.",
    addAddress: "Add Address",
    finishAddressHistory: "Finish Address History",
    noEntries: "You did not enter any address history.",
    unit: "Unit",
  },
  militaryService: {
    heading: "Military Service",
    borrowerSubHeading: "Do you have any military service to include?",
    coBorrowerSubHeading:
      "Does your co-borrower have any military service to include?",
  },
  employmentPick: {
    addEmployment: "Yes, add employment",
    noEmployment: "No, I was not employed",
    coboNoEmployment: "No, my co-borrower was not employed",
  },
  employmentHistory: {
    heading: "Employment History",
    borrowerSubHeading: "Have you been employed in the last two years?",
    coBorrowerSubHeading:
      "Has your co-borrower been employed in the last two years?",
  },
  employmentValues: {
    heading: "Employment Income",
    borrowerSubHeading: "Tell us a bit more about your employment income.",
    coBorrowerSubHeading:
      "Tell us a bit more about your co-borrower’s employment income.",
    employerPartyQuestion:
      "Are you employed by a family member, property seller, real estate agent, or other party to the transaction?",
    doYouRecieve: "Do you receive",
    inThisPosition: "in this position?",
  },
  employmentOverview: {
    heading: "Employment Overview",
    subHeading: "Do you have any more jobs to include?",
    twoYearsRequired: "At least 2 years of employment history is required.",
    noEmployment: "You selected that you have no employment.",
    noEntries: "You did not enter any employment history.",
    addEmployment: "Add Employment",
    verifyEmployment: "Verify Employment Gaps",
    finishEmployment: "Finished With Employment",
  },
  employmentValidation: {
    heading: "Before Continuing...",
    subHeading:
      "We found gaps in your 2 year employment history. Verify the following information is correct.",
  },
  verifyIncome: {
    overviewHeading: "Verify Income",
    mainHeading: "Save time by verifying your income.",
    mainSubHeading:
      "Securely verify your employers below, and we'll import your W2s, paystubs, and other documents needed for your loan.",
    cantVerifyHeading: "Looks like we couldn't verify your employment with ",
    cantVerifySubHeading:
      "Would you like to try again? If not, you'll need to upload income documents once we've reviewed your application.",
    tryAgainButton: "Yes, I'll try again",
    continueWithoutVerificationButton: "No, I'll continue without verifying",
    secureText: "We keep your information secure and confidential.",
  },
  otherIncome: {
    heading: "Other Income",
    subHeading:
      "Include all other income. To begin, select a source. You will be able to add more later.",
    addButton: "Add Other Income",
    selectQuestion: "Select Other Income Source",
    incomeTypeSubHeadings: {
      alimony: {
        label: "Alimony/Child Support",
        borrower:
          "Please provide more information about the alimony or child support received.",
        coBorrower:
          "Please provide more information about the alimony or child support received.",
      },
      automobile_allowance: {
        label: "Automobile Allowance",
        borrower: "Tell us a bit more about your automobile allowance.",
        coBorrower:
          "Tell us a bit more about your co-borrower’s automobile allowance.",
      },
      boarder_income: {
        label: "Boarder Income",
        borrower:
          "Please provide more information about the boarder income received.",
        coBorrower:
          "Please provide more information about the boarder income received.",
      },
      capital_gains: {
        label: "Capital Gains",
        borrower:
          "Please provide more information about the capital gains income received.",
        coBorrower:
          "Please provide more information about the capital gains income received.",
      },
      child_support: {
        label: "Alimony/Child Support",
        borrower:
          "Please provide more information about the alimony or child support received.",
        coBorrower:
          "Please provide more information about the alimony or child support received.",
      },
      disability: {
        label: "Disability",
        borrower: "Tell us a bit more about your disability income.",
        coBorrower:
          "Tell us a bit more about your co-borrower’s disability income.",
      },
      foster_care: {
        label: "Foster Care",
        borrower: "Tell us about your foster care income.",
        coBorrower: "Tell us about your co-borrower’s foster care income.",
      },
      housing_or_parsonage: {
        label: "Housing or Parsonage",
        borrower: "Tell us about your housing or parsonage.",
        coBorrower: "Tell us about your co-borrower’s housing or parsonage.",
      },
      interest_and_dividends: {
        label: "Interest and Dividends",
        borrower: "Tell us a bit more about your interest and dividends.",
        coBorrower:
          "Tell us a bit more about your co-borrower’s interest and dividends.",
      },
      mortgage_credit_certificate: {
        label: "Mortgage Credit Certificate",
        borrower: "Tell us about your Mortgage Credit Certificate.",
        coBorrower:
          "Tell us about your co-borrower’s Mortgage Credit Certificate.",
      },
      mortgage_differential_payments: {
        label: "Mortgage Differential Payments",
        borrower:
          "Please provide more information about the mortgage differential payments income received.",
        coBorrower:
          "Please provide more information about the mortgage differential payments income received.",
      },
      notes_receivable: {
        label: "Notes Receivable",
        borrower: "Tell us a bit more about your notes receivable.",
        coBorrower:
          "Tell us a bit more about your co-borrower’s notes receivable.",
      },
      public_assistance: {
        label: "Public Assistance",
        borrower: "Tell us about your public assistance income.",
        coBorrower:
          "Tell us about your co-borrower’s public assistance income.",
      },
      retirement: {
        label: "Retirement",
        borrower:
          "Please provide more information about the retirement income received.",
        coBorrower:
          "Please provide more information about the retirement income received.",
      },
      royalty_payments: {
        label: "Royalty Payments",
        borrower: "Tell us a bit more about your royalty payments.",
        coBorrower:
          "Tell us a bit more about your co-borrower’s royalty payments.",
      },
      separate_maintenance: {
        label: "Separate Maintenance",
        borrower: "Tell us about your separate maintenance.",
        coBorrower: "Tell us about your co-borrower’s separate maintenance.",
      },
      social_security: {
        label: "Social Security",
        borrower:
          "Please provide more information about social security income received.",
        coBorrower:
          "Please provide more information about social security income received.",
      },
      trust: {
        label: "Trust",
        borrower: "Tell us a bit more about your trust fund income.",
        coBorrower:
          "Tell us a bit more about your co-borrower’s trust fund income.",
      },
      unemployment_benefits: {
        label: "Unemployment Benefits",
        borrower: "Tell us about your unemployment benefits.",
        coBorrower: "Tell us about your co-borrower’s unemployment benefits.",
      },
      va_compensation: {
        label: "VA Compensation",
        borrower:
          "Please provide more information about the VA compensation received.",
        coBorrower:
          "Please provide more information about the VA compensation received.",
      },
      other: {
        label: "Other",
        borrower: "Tell us a bit more about your other income.",
        coBorrower: "Tell us a bit more about your co-borrower’s other income.",
      },
      netRentalIncome: {
        borrower: "Please provide more information about your rental income.",
        coBorrower:
          "Please provide more information about your co-borrower’s rental income.",
      },
    },
  },
  otherIncomeOverview: {
    heading: "Other Income Overview",
    subHeading: "Do you have any more income sources to include?",
    requiredText: "At least one income source is required.",
    noOtherIncome: "You did not enter any other income.",
    addIncome: "Add Income",
  },
  declarations: {
    heading: "Declarations",
    borrowerSubHeading:
      "In this section we will gain an understanding of your legal history.",
    explanation: "Explanation",
    coBorrowerSubHeading:
      "In this section we will gain an understanding of your co-borrower's legal history.",
    section01: "Declarations about this property",
    section02: "Declarations about finances",
    declaration01: "Will you occupy the property as your primary residence?",
    declaration02:
      "Have you had ownership interest in another property in the last three years?",
    declaration03: "What type of property did you own?",
    declaration04: "How did you hold title to the property?",
    declaration05:
      "If this is a Purchase Transaction: Do you have a family relationship or business affiliation with the seller of the property?",
    declaration06:
      "Are you borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?",
    declaration07: "What is the amount of this money?",
    declaration08:
      "Have you or will you be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?",
    declaration09:
      "Have you or will you be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?",
    declaration10:
      "Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?",
    declaration11:
      "Are you a co-signer or guarantor on any debt or loan that is not disclosed on this application?",
    declaration12: "Are there any outstanding judgments against you?",
    declaration13:
      "Are you currently delinquent or in default on a Federal debt?",
    declaration14:
      "Are you party to a lawsuit in which you potentially have any personal financial liability?",
    declaration15:
      "Have you conveyed title to any property in lieu of foreclosure in the past 7 years?",
    declaration16:
      "Within the past 7 years, have you completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the lender agreed to accept less than the outstanding mortgage balance due?",
    declaration17: "Have you had property foreclosed upon in the last 7 years?",
    declaration18: "Have you declared bankruptcy within the past 7 years?",
    declaration19: "Identify the type of bankruptcy",
  },
  coboDeclarations: {
    section01: "Declarations about this property",
    section02: "Declarations about finances",
    declaration01:
      "Will your co-borrower occupy the property as their primary residence?",
    declaration02:
      "Has your co-borrower had ownership interest in another property in the last three years?",
    declaration03: "What type of property does your co-borrower own?",
    declaration04: "How did your co-borrower hold title to the property?",
    declaration05:
      "If this is a Purchase Transaction: Does your co-borrower have a family relationship or business affiliation with the seller of the property?",
    declaration06:
      "Is your co-borrower borrowing any money for this real estate transaction (e.g., money for your closing costs or down payment) or obtaining any money from another party, such as the seller or realtor, that you have not disclosed on this loan application?",
    declaration07: "What is the amount of this money?",
    declaration08:
      "Has your co-borrower or will your co-borrower be applying for a mortgage loan on another property (not the property securing this loan) on or before closing this transaction that is not disclosed on this loan application?",
    declaration09:
      "Has your co-borrower or will your co-borrower be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this loan that is not disclosed on this application?",
    declaration10:
      "Will this property be subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid through your property taxes (e.g., the Property Assessed Clean Energy Program)?",
    declaration11:
      "Is your co-borrower a co-signer or guarantor on any debt or loan that is not disclosed on this application?",
    declaration12:
      "Are there any outstanding judgments against your co-borrower?",
    declaration13:
      "Is your co-borrower currently delinquent or in default on a Federal debt?",
    declaration14:
      "Is your co-borrower party to a lawsuit in which you potentially have any personal financial liability?",
    declaration15:
      "Has your co-borrower conveyed title to any property in lieu of foreclosure in the past 7 years?",
    declaration16:
      "Within the past 7 years, has your co-borrower completed a pre-foreclosure sale or short sale, whereby the property was sold to a third party and the lender agreed to accept less than the outstanding mortgage balance due?",
    declaration17:
      "Has your co-borrower had property foreclosed upon in the last 7 years?",
    declaration18:
      "Has your co-borrower declared bankruptcy within the past 7 years?",
    declaration19: "Identify the type of bankruptcy",
  },
  an2Declarations: {
    declaration01:
      "Do you intend to occupy the home as your primary residence?",
    declaration02: "Are there any outstanding judgments against you?",
    declaration03: "Have you been declared bankrupt within the past 7 years?",
    declaration04:
      "Have you had property foreclosed upon or given title or deed in lieu thereof in the last 7 years?",
    declaration05: "Are you party to a lawsuit?",
    declaration06:
      "Have you directly or indirectly been obligated on any loan which resulted in foreclosure, transfer of title in lieu of foreclosure, or judgement?",
    declaration07:
      "Are you presently delinquent or in default on any Federal debt or any other loan, mortgage, financial obligation, bond, or loan guarantee?",
    declaration08:
      "Are you obligated to pay alimony, child support, or separate maintenance?",
    declaration09: "Is any part of the down payment borrowed?",
    declaration10: "Are you a co-maker or endorser on a note?",
    declaration11:
      "Have you had an ownership interest in a property the last three years?",
    explanation: "Write your explanation here. Max 100 characters.",
    propertyType: "What type of property did you own?",
    holdTitle: "How did you hold title to the property?",
    citizenship: "What is your U.S. Citizenship status?",
    directIndirectLoan: "Directly or Indirectly obligated on forclosed loan",
    borrowedDownPayment: "Borrowed down payment",
    coMakerNote: "Co-maker or endorser on note",
    ownershipInterest: "Ownership interest in property",
  },
  coboAn2Declarations: {
    declaration01:
      "Does your co-borrower intend to occupy the home as their primary residence?",
    declaration02:
      "Are there any outstanding judgments against your co-borrower?",
    declaration03:
      "Has your co-borrower been declared bankrupt within the past 7 years?",
    declaration04:
      "Has your co-borrower had property foreclosed upon or given title or deed in lieu thereof in the last 7 years?",
    declaration05: "Is your co-borrower party to a lawsuit?",
    declaration06:
      "Has your co-borrower directly or indirectly been obligated on any loan which resulted in foreclosure, transfer of title in lieu of foreclosure, or judgement?",
    declaration07:
      "Is your co-borrower presently delinquent or in default on any Federal debt or any other loan, mortgage, financial obligation, bond, or loan guarantee?",
    declaration08:
      "Is your co-borrower obligated to pay alimony, child support, or separate maintenance?",
    declaration09: "Is any part of the down payment borrowed?",
    declaration10: "Is your co-borrower a co-maker or endorser on a note?",
    declaration11:
      "Has your co-borrower had an ownership interest in a property the last three years?",
    explanation:
      "Write your co-borrower's explanation here. Max 100 characters.",
    propertyType: "What type of property did your co-borrower own?",
    holdTitle: "How did your co-borrower hold title to the property?",
    citizenship: "What is your co-borrower's U.S. Citizenship status?",
    directIndirectLoan: "Directly or Indirectly obligated on forclosed loan",
    borrowedDownPayment: "Borrowed down payment",
    coMakerNote: "Co-maker or endorser on note",
    ownershipInterest: "Ownership interest in property",
  },
  demographics: {
    heading: "Demographics",
    borrowerSubHeading:
      "Select all that apply to you. Providing your demographic information is optional, but encouraged as it helps ensure borrowers are being treated fairly.",
    coBorrowerSubHeading:
      "Select all that apply to you. Providing your co-borrower's demographic information is optional, but encouraged as it helps ensure borrowers are being treated fairly.",
    borrowerSubHeadingRequired:
      "Select all that apply to you. Providing your demographic information is encouraged as it helps ensure borrowers are being treated fairly.",
    coBorrowerSubHeadingRequired:
      "Select all that apply to you. Providing your co-borrower's demographic information is encouraged as it helps ensure borrowers are being treated fairly.",
    ethnicity: "Ethnicity",
    race: "Race",
    origin: "Origin",
    sex: "Sex",
    dnp: "Do not wish to provide",
    hispanicOrLatino: "Hispanic or Latino",
    notHispanicOrLatino: "Not Hispanic or Latino",
    mexican: "Mexican",
    puerto_rican: "Puerto Rican",
    cuban: "Cuban",
    otherHispanic: "Other Hispanic or Latino",
    americanIndian: "American Indian or Alaska Native",
    asian: "Asian",
    asian_indian: "Asian Indian",
    chinese: "Chinese",
    filipino: "Filipino",
    japanese: "Japanese",
    korean: "Korean",
    vietnamese: "Vietnamese",
    otherAsian: "Other Asian",
    africanAmerican: "Black or African American",
    pacificIslander: "Native Hawaiian or Other Pacific Islander",
    native_hawaiian: "Native Hawaiian",
    guamanian: "Guamanian or Chamorro",
    samoan: "Samoan",
    otherPacific: "Other Pacific Islander",
    white: "White",
    female: "Female",
    male: "Male",
    tribe: "Enrolled or Principal Tribe",
    notProvided: "Not Provided",
  },
  visualObservation: {
    applicationTakenQuestion: "Was the application taken in person?",
    heading:
      "Declarations for Applications Taken in Person (Only Visible to LO Teams)",
    ethnicityQuestion:
      "Was the ethnicity of the Borrower collected on the basis of visual observation or surname?",
    sexQuestion:
      "Was the sex of the Borrower collected on the basis of visual observation or surname?",
    raceQuestion:
      "Was the race of the Borrower collected on the basis of visual observation or surname?",
  },
  homeownershipEducation: {
    homeownershipEducationQuestion:
      "Has the Borrower completed homeownership education (group or web-based classes) within the last 12 months?",
    homeownershipEducationFormat: "Education Format",
    homeownershipEducationProviderApprovedByHUD:
      "If a HUD-approved agency, provide Housing Counseling Agency ID",
    homeownershipEducationProviderNotApprovedByHUD:
      "If not a HUD-approved agency, or unsure of HUD approval, provide the name of the Housing Education Program:",
    homeownershipEducationDateOfCompletion: "Date of Completion",
    heading: "Homeownership Education",
    completed: "Homeownership Education Completed",
    agencyId: "Agency ID",
    programName: "Program Name",
    dateCompleted: "Education Date Completed",
  },
  housingCounseling: {
    housingCounselingQuestion:
      "Has the Borrower completed housing counseling (customized counselor-to-client services) within the last 12 months?",
    housingCounselingFormat: "Counseling Format",
    housingCounselingProviderApprovedByHUD:
      "If a HUD-approved agency, provide Housing Counseling Agency ID",
    housingCounselingProviderNotApprovedByHUD:
      "If not a HUD-approved agency, or unsure of HUD approval, provide the name of the Housing Counseling Agency:",
    housingCounselingDateOfCompletion: "Date of Completion",
    heading: "Housing Counseling",
    completed: "Housing Counseling Completed",
    agencyId: "Agency ID",
    agencyName: "Agency Name",
    dateCompleted: "Counseling Date Completed",
  },
  languagePreference: {
    languagePreferenceQuestion: "Language Preference",
    languagePreferenceOtherQuestion: "Language Preference Other",
    heading: "Language Preference",
    borrowerSubHeading: "Tell us about the language you prefer to work with.",
    coBorrowerSubHeading:
      "This question requests information to see if communications are available to assist you in your preferred language.",
    language: "Language",
    preferredLanguageRequired: "Preferred language is required",
  },
  borrowerReview: {
    heading: "Review Your Info",
  },
  coBorrowerReview: {
    heading: "Review Your Co-Borrower’s Info",
  },
  assetsIntro: {
    heading: "Your Assets",
    borrowerSubHeading: "Tell us about the financial assets you have.",
    coBorrowerSubHeading:
      "Tell us about the financial assets you and your co-borrower have.",
  },
  assetsCreditsIntro: {
    heading: "Your Assets & Credits",
    borrowerSubHeading:
      "Tell us about the financial assets or credits you have.",
    coBorrowerSubHeading:
      "Tell us about the financial assets or credits you and your co-borrower have.",
  },
  assetsCredits: {
    heading: "Add Assets & Credits",
    borrowerSubHeading:
      "Include all of the financial assets you have. To begin, select the type of asset or credit. You will be able to add more later.",
    coBorrowerSubHeading:
      "Include all of the financial assets you and your co-borrower have. To begin, select the type of asset or credit. You will be able to add more later.",
  },
  assets: {
    heading: "Add Assets",
    borrowerSubHeading:
      "Include all of the financial assets you have. To begin, select the type of asset. You will be able to add more later.",
    coBorrowerSubHeading:
      "Include all of the financial assets you and your co-borrower have. To begin, select the type of asset. You will be able to add more later.",
    sectionHeading: "ASSET",
    selectQuestion: "Selected asset type",
    asset: "Asset",
    assetPlural: "Assets",
    addButton: "Add Asset",
    checkingAccount: "Checking Account",
    savingsAccount: "Savings Account",
    checking: "Checking",
    savings: "Savings",
    checkingSavings: "Checking/Savings",
    checkingSavingsQuestion: "Savings or Checking Account?",
    stockBondQuestion: "Stock or Bond?",
    retirement: "Retirement (e.g. 401k, IRA)",
    bridge_loan_proceeds: "Bridge Loan Proceeds",
    cash_value_of_life_insurance: "Cash Value of Life Insurance",
    certificate_of_deposit: "Certificate of Deposit",
    earnest_money: "Earnest Money",
    employer_assistance: "Employer Assistance",
    individual_development_account: "Individual Development Account",
    money_market: "Money Market",
    mutual_fund: "Mutual Fund",
    proceeds_non_real_estate_sale:
      "Proceeds from Sale of Non-Real Estate Asset",
    proceeds_real_estate_sale: "Proceeds from Sale of Real Estate Asset",
    rent_credit: "Rent Credit",
    secured_borrowed_funds: "Secured Borrowed Funds",
    stockBond: "Stock/Bond",
    stocks: "Stocks",
    bonds: "Bonds",
    stock_options: "Stock Options",
    sweat_equity: "Sweat Equity",
    trade_equity: "Trade Equity",
    trust_account: "Trust Account",
    unsecured_borrowed_funds: "Unsecured Borrowed Funds",
    lot_equity: "Lot Equity",
    relocation_funds: "Relocation Funds",
    other: "Other Asset",
    otherCredit: "Other Credit",
    none: "None",
    creditSourceType: "Credit Source Type (only visible to LO teams)",
    assetSubType: "Asset Type (only visible to LO teams)",
    assetTypeSubHeadings: {
      checking:
        "Please provide the details for your accounts by using our secure login or completing manually.",
      savings:
        "Please provide the details for your accounts by using our secure login or completing manually.",
      money_market: "Please provide the details for your account.",
      certificate_of_deposit:
        "Please provide the details for your certificate of deposit.",
      mutual_fund: "Please provide the details for your mutual funds.",
      stocks: "Please provide the details for your account.",
      bonds: "Please provide the details for your account.",
      stock_options: "Please provide the details for your stock options.",
      retirement: "Please provide the details for your retirement fund.",
      bridge_loan_proceeds:
        "Please provide the details for your bridge loan proceeds.",
      individual_development_account:
        "Please provide the details for your Individual Development Account.",
      trust_account: "Please provide the details for your trust account.",
      cash_value_of_life_insurance:
        "Please provide the details for your cash value of life insurance.",
      earnest_money: "Please provide the details for your earnest money.",
      proceeds_non_real_estate_sale:
        "Please provide the details for your proceeds from the sale of a non-real estate asset.",
      proceeds_real_estate_sale:
        "Please provide the details for the proceeds from real estate property to be sold on or before closing.",
      sweat_equity: "Please provide the details for your sweat equity.",
      employer_assistance:
        "Please provide the details for your employer assistance.",
      rent_credit: "Please provide the details for your rent credit.",
      secured_borrowed_funds:
        "Please provide the details for your secured borrower funds.",
      trade_equity: "Please provide the details for your trade equity.",
      unsecured_borrowed_funds:
        "Please provide the details for your unsecured borrowed funds.",
      lot_equity: "Please provide the details for your lot equity.",
      relocation_funds: "Please provide the details for your relocation funds.",
      other: "Please provide the details for any other assets.",
    },
  },
  assetsOverview: {
    heading: "Assets Overview",
    subHeading: "Do you have more assets to include?",
    assetRequired: "Review the Assets page before submitting.",
    noAssets: "You did not enter any assets.",
    addAsset: "Add Asset",
  },
  assetsCreditsOverview: {
    heading: "Assets & Credits Overview",
    subHeading: "Do you have more assets or credits to include?",
    assetRequired: "Review the Assets & Credits page before submitting.",
    noAssets: "You did not enter any assets or credits.",
    addAsset: "Add Asset or Credit",
  },
  assetsCreditsReview: {
    heading: "Review your Assets & Credits",
  },
  assetsReview: {
    heading: "Review your Assets",
  },
  liabilitiesIntro: {
    heading: "Your Liabilities",
    borrowerSubHeading: "Please let us know what liabilities you have.",
    coBorrowerSubHeading:
      "Please let us know what liabilities you and your co-borrower have.",
  },
  liabilities: {
    heading: "Add Liabilities",
    borrowerSubHeading:
      "Include all of the liabilities you have. To begin, select the type of liability. You will be able to add more later.",
    coBorrowerSubHeading:
      "Include all of the liabilities you and your co-borrower have. To begin, select the type of liability. You will be able to add more later.",
    sectionHeading: "LIABILITY",
    selectQuestion: "Select Liability Type",
    liability: "Liability",
    liabilityPlural: "Liabilities",
    addButton: "Add Liability",
    alimony: "Alimony",
    child_support: "Child Support",
    installment: "Installment",
    job_related_expenses: "Job Related Expenses",
    lease: "Lease",
    open_30_day: "Open 30-Day",
    otherAccount: "Other Account",
    revolving: "Revolving Charge Account",
    separate_maintenance: "Separate Maintenance",
    other: "Other Non-Account",
    none: "None",
    liabilityTypeSubHeadings: {
      revolving: "Please provide details for your revolving liability.",
      installment: "Please provide details about your installment.",
      open_30_day: "Please provide details about your open 30-day account.",
      lease: "Please provide details about your lease.",
      otherAccount: "Please provide details about your other account",
      alimony: "Please provide details about your alimony or child support.",
      child_support:
        "Please provide details about your alimony or child support.",
      separate_maintenance:
        "Please provide details about your separate maintenance.",
      job_related_expenses:
        "Please provide details about your job related expenses.",
      other: "Please provide the details for any other liabilities.",
    },
  },
  liabilitiesOverview: {
    heading: "Liabilities Overview",
    subHeading: "Do you have more liabilities to include?",
    liabilityRequired: "Review the Liabilities page before submitting.",
    noLiabilities: "You did not enter any liabilities.",
    addLiability: "Add Liability",
  },
  liabilitiesReview: {
    heading: "Review Your Liabilities",
  },
  realEstateIntro: {
    heading: "Real Estate Owned",
    borrowerSubHeading:
      "Include all of the real estate you own, including your current, primary residence.",
    coBorrowerSubHeading:
      "Include all of the real estate you and your co-borrower own, including your current, primary residence.",
  },
  realEstate: {
    realEstate: "Real Estate",
    heading: "Add Real Estate",
    // borrowerSubHeading : 'Include all of the real estate you own other than your current, primary residence.',
    // coBorrowerSubHeading : 'Include all of the real estate you and your co-borrower own other than your current, primary residence.',
    addButton: "Add Real Estate",
    addCreditorButton: "Add another mortgage for this property",
    yesAdd: "Yes, add real estate",
    noAdd: "No, I don't own any real estate",
  },
  realEstateValues: {
    heading: "Add Real Estate",
    borrowerSubHeading:
      "Include all of the real estate you own, including your current, primary residence.",
    coBorrowerSubHeading:
      "Include all of the real estate you and your co-borrower own, including your current, primary residence.",
    addMortgageDescription: "Do you have any mortgages on this property?",
    propertyLoan: "Property Loan",
    mortgage: "Mortgage",
    details: "Details",
  },
  realEstateOverview: {
    heading: "Real Estate Overview",
    subHeading: "Do you have more real estate to include?",
    realEstateRequired: "Review the Real Estate page before submitting.",
    noRealEstate: "You did not enter any real estate.",
    addRealEstate: "Add Real Estate",
  },
  realEstateReview: {
    heading: "Review Your Real Estate",
  },
  loanInfoIntro: {
    heading: "Your Loan",
    borrowerSubHeading: "Tell us about the type of loan you are looking for.",
    coBorrowerSubHeading:
      "Tell us about the type of loan you and your co-borrower are looking for.",
  },
  propertyInfo: {
    heading: "Property Info",
    subHeading: "Tell us about the property you're looking to purchase.",
    underContract: "Are you currently under contract to purchase a property?",
    notUnderContractHeading: "Where are you searching for properties?",
    notUnderContractSubHeading:
      "Don’t worry if you have multiple cities, postal codes, or even states in mind. Just enter your preferred area for now.",
    refiSubHeading: "Tell us about the property you're looking to refinance.",
    otherSubHeading: "Tell us about the property you're looking to finance.",
    otherMortgages: "Do you have other new mortgage loans on this property?",
    otherMortgagesTbd:
      "Will you have other new mortgage loans on this property?",
    noMortgages: "You haven't added any mortgages yet.",
    addMortgage: "Add Mortgage",
    refiIsCurrentAddress: "Are you refinancing your current address?",
    yearAcquired: "Year Acquired",
    lienAmount: "Amount of Existing Liens",
    refiReason: "Reason for Refinancing",
    refiCashOut: "Cash-Out Amount",
    marketValue: "Market Value",
    purchaseHelp: "Haven't found a place yet? Approximations are okay.",
    intendedOccupancy: "Intended Occupancy",
    currentOccupancy: "Current Occupancy",
    isCommunityLendingProgram:
      "Is this a community lending program? (only visible to LO teams)",
    communityLendingProductType: "Community Lending Product Type",
    communitySecondsRepaymentType: "Community Seconds Repayment Type",
    yearBuilt: "Year the Property was Built",
  },
  realtorInfo: {
    heading: "Working with a Realtor?",
    questionOne: "Are you working with a realtor?",
    questionTwo: "Do you allow your realtor to see details about your loan?",
    whatDoesThisMean: "What does this mean?",
    realtorViewingPermission: "Realtor Viewing Permission",
    realtorViewingPermissionP1:
      "If you allow, your realtor will have a restricted view of your loan details. This means they will only be able to view some details, such as the loan status and loan type. They will not be able to see your loan application, sensitive information, or any documents provided in connection to your loan (e.g. W2s, tax returns).",
    realtorViewingPermissionP2:
      "We want to make your home buying experience as easy as possible. By granting your realtor restricted view of your loan details, the team can work more quickly through the process. Please follow up with your lending officer with further questions.",
    doIHaveToProvidePermission: "Do I Have to Provide Permisson",
    doIHaveToProvidePermissionP1:
      "You are not required to allow restricted viewing rights to your realtor, however, it is encouraged.",
  },
  referralPartner: {
    heading: "Who referred you?",
    questionOne: "Did someone refer you to",
    questionTwo: "Did someone refer you to",
    noOne: "No one",
    questionThree: "Who referred you to",
    referralRequired: "Please enter your referral partner's name",
  },
  loanPurposePick: {
    heading: "Loan Purpose",
    subHeading: "Please tell us what type of loan you are applying for.",
    purchase: "Purchase",
    refinance: "Refinance",
    other: "Other",
  },
  loanPurposeScreen: {
    heading: "Loan Purpose",
    subHeading: "Which type of loan are you applying for?",
    options: {
      purchase: {
        description: "I'm making a new purchase.",
        label: "Purchase",
      },
      refinance: {
        description: "I'm refinancing an existing loan.",
        label: "Refinance",
      },
      other: {
        description: "I'm applying for something else.",
        label: "Other",
      },
    },
  },
  loanInfo: {
    heading: "Loan Info",
    subHeading: "Provide information about your loan here.",
  },
  otherLoans: {
    heading: "Other Mortgages",
    subHeading:
      "Please tell us about any other mortgages you will have on this property.",
    addButton: "Add Other Mortgage",
    sectionHeading: "OTHER MORTGAGE",
    otherMortgageSingular: "Other Mortgage",
    noOtherMortgages: "You did not enter any other mortgages.",
  },
  rentalIncome: {
    heading: "Rental Income",
    subHeading:
      "Let us know if you plan to make rental income on the property.",
  },
  giftsAndGrants: {
    heading: "Add Gifts and Grants",
    borrowerSubHeading:
      "Add all gifts and grants that you have been given or will receive for this loan.",
    coBorrowerSubHeading:
      "Add all gifts and grants that you and your co-borrower have been given or will receive for this loan.",
    reviewHeading: "Gifts and Grants",
    addButton: "Add Gift/Grant",
    removeButton: "Remove this Gift/Grant",
    sectionHeading: "GIFT/GRANT",
    giftsGrant: "Gift/Grant",
    cash_gift: "Cash Gift",
    gift_of_equity: "Gift of Equity",
    grant: "Grant",
    none: "None",
    giftGrantTypeSubHeadings: {
      cash_gift: "Please provide more information about this cash gift.",
      gift_of_equity:
        "Please provide more information about this gift of equity.",
      grant: "Please provide more information about this grant.",
    },
  },
  giftGrantOverview: {
    heading: "Gifts and Grants Overview",
    subHeading: "Do you have more gifts or grants to include?",
    giftRequired: "Review the Gifts and Grants page before submitting.",
    noGift: "You did not enter any gifts or grants.",
    addGift: "Add Gift/Grant",
  },
  loanInfoReview: {
    heading: "Review Your Loan Info",
  },
  finalReview: {
    heading: "Review & Submit",
    subHeading:
      "Before submitting your application, take a moment to ensure your information is complete and accurate. You can edit the section or mark the section done.",
    borrowerInfo: "Borrower Information",
    coboInfo: "Co-Borrower Information",
    buttonText: "Review Next Section",
    continueButtonText: "Continue to Submission",
  },
  acceptTerms: {
    termsText: "I agree to",
    termsLink: "Terms of Use and Privacy Policy",
  },
  accountChek: {
    consentText: "I agree to the",
    consentLinkText: "Terms of Service",
    consentText2:
      "and request that AccountChek® by FormFree® act as an intermediary on my behalf to furnish information to a prospective creditor in order to further my application.",
  },
  finalSubmit: {
    heading: "Submit",
    subHeading: "You're Ready to Submit",
    bodyText:
      "By selecting the Submit Button you will no longer be able to access your application. Your loan officer will follow up with you as soon as possible.",
    buttonText: "Submit Application",
    borrower: "BORROWER:",
    coBorrower: "CO-BORROWER:",
    consentText: "I consent to",
    consentLinkText: "do business electronically",
    creditCheckText:
      'By clicking the "I/We agree to give authorization to run a credit check on financial history" checkbox, I/we hereby grant permission for us to obtain all information deemed necessary to process your mortgage loan application. This information includes, but is not limited to, your past and present employment status, your deposit accounts, your past and present consumer credit record, your mortgage record, and/or your rental record.',
  },
  finalCompleteModal: {
    heading: "Submission Successful",
    body: "Your application has been sent! We'll be in contact with you soon.",
  },
  errorModal: {
    heading: "Sorry, something went wrong!",
    body: "Please contact your loan officer for assistance.",
  },
  logoutErrorModal: {
    heading: "Your session has expired",
    body: 'Due to inactivity, we logged you out of your session. To continue with your application, enter your password below and click "Log in".',
    noPasswordBody:
      "Due to inactivity, your session has expired. To continue with your application, click the Continue Application button below.",
    login: "Log in",
    noPasswordLogin: "Continue Application",
    exit: "Exit Application",
  },
  quickAssist: {
    text1: "Need help from your lending team?",
    text2: "Request for help sent ",
    button1: "Request support",
    button2: "Send another",
    yesterday: "yesterday",
    tomorrow: "tomorrow",
    at: "at",
    atTime: "at ",
    tooltipMobile1: "Add your mobile number in ",
    tooltipMobile2: " before requesting help.",
    tooltipContactInfoLink: "Your Info > Contact Info",
    tooltipRateLimit:
      "You have exceeded the maximum number of requests allowed in a 24-hour period. You may send another request ",
    loanOfficer: "Loan Officer",
  },
  quickAssistModal: {
    headingLine1: "Request support",
    headingLine2: "from your lending team",
    body: "If you’re having trouble with your application, request help from your lending team below. You may not hear back right away, but they’ll receive your request and will reach out as soon as possible.",
    textareaPlaceholder1: "Add your question or message for",
    textareaPlaceholder2: " here...",
    cancelButton: "Cancel",
    sendButton: "Send request to ",
    sendingButton: "Sending request to ",
    rateLimitWarning:
      "This will be your fifth request for support in the past 24 hours. If you submit this request, you will not be able to make another one until ",
  },
  weekdays: {
    Monday: "Monday",
    Tuesday: "Tuesdsay",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Sunday: "Sunday",
  },
  sessionTimeout: {
    heading: "Your session is expiring",
    bodyOne: "Due to inactivity, you will be logged out in",
    bodyTwo: "seconds. Do you want to continue your session?",
    buttonText: "Stay signed in",
  },
  forceComplete: {
    heading: "This loan application has been completed by your lender.",
    subHeading: "Click on the button below to view your loan.",
    buttonText: "Go to Borrower Portal",
  },
  additionalQuestions: {
    heading: "Additional Questions",
    subHeading: "Please answer these additional questions.",
  },
  selectOptions: {
    married: "Married",
    separated: "Separated",
    unmarried: "Unmarried",
    civil_union: "Civil Union",
    domestic_partnership: "Domestic Partnership",
    registered_reciprocal_beneficiary_relationship:
      "Registered Reciprocal Beneficiary Relationship",
    citizen: "U.S. Citizen",
    permanent_alien: "Permanent Resident Alien",
    non_permanent_alien: "Non-Permanent Resident Alien",
    currentlyServing: "Currently Serving",
    currentlyRetired:
      "Currently Retired, Discharged, or Separated from Service",
    nonActivated: "Not-Activated Reserve",
    survivingSpouse: "Surviving Spouse",
    own: "Own",
    rent: "Rent",
    living_rent_free: "Living Rent Free",
    no_primary_expense: "No Primary Housing Expense",
    lessThanTwentyFive: "Less than 25%",
    twentyFiveOrMore: "25% or more",
    alimony: "Alimony",
    child_support: "Child Support",
    primary_residence: "Primary Residence",
    primary: "Primary Residence",
    primary_residence_2_to_4: "Primary Residence (2-4 Unit)",
    fha_secondary_residence: "FHA Secondary Residence",
    second_home: "Second Home",
    secondary: "Second Home",
    investment_property: "Investment Property",
    investment: "Investment Property",
    sole: "By yourself",
    yourself: "By yourself",
    jointly_with_spouse: "With your spouse",
    jointspouse: "With your spouse",
    jointly_with_other: "Jointly with another person",
    jointother: "Jointly with another person",
    chapter_7: "Chapter 7",
    chapter_11: "Chapter 11",
    chapter_12: "Chapter 12",
    chapter_13: "Chapter 13",
    checking: "Checking",
    savings: "Savings",
    stocks: "Stocks",
    bonds: "Bonds",
    owned: "Owned",
    pending: "Pending",
    rental: "Rental",
    sold: "Sold",
    pending_sale: "Pending Sale",
    retained: "Retained",
    fha: "FHA",
    va: "VA",
    conventional: "Conventional",
    usda_rd: "USDA-RD",
    other: "Other",
    purchase: "Purchase",
    refinance: "Refinance",
    yes: "Yes",
    no: "No",
    first: "First Lien",
    subordinate: "Subordinate Lien",
    deposited: "Deposited",
    notDeposited: "Not Deposited",
    relative: "Relative",
    unmarried_partner: "Unmarried Partner",
    employer: "Employer",
    religious_nonprofit: "Religious Nonprofit",
    community_nonprofit: "Community Nonprofit",
    federal_agency: "Federal Agency",
    state_agency: "State Agency",
    local_agency: "Local Agency",
    lender: "Lender",
    funds_source_type_borrower: "Borrower",
    funds_source_type_builder: "Builder",
    funds_source_type_community_nonprofit: "Community Nonprofit",
    funds_source_type_employer: "Employer",
    funds_source_type_federal_agency: "Federal Agency",
    funds_source_type_institutional: "Institutional",
    funds_source_type_lender: "Lender",
    funds_source_type_local_agency: "Local Agency",
    funds_source_type_non_originating_financial_institution:
      "Non-Originating Financial Institution",
    funds_source_type_non_parent_relative: "Non-Parent Relative",
    funds_source_type_other: "Other",
    funds_source_type_parent: "Parent",
    funds_source_type_property_seller: "Property Seller",
    funds_source_type_relative: "Relative",
    funds_source_type_religious_nonprofit: "Religious Nonprofit",
    funds_source_type_rural_housing_service: "Rural Housing Service",
    funds_source_type_state_agency: "State Agency",
    funds_source_type_unknown: "Unknown",
    funds_source_type_unmarried_partner: "Unmarried Partner",
    funds_source_type_unrelated_friend: "Unrelated Friend",
    borrower_paid_outside_closing: "Borrower Paid Outside Closing",
    builder_developer: "Builder Developer",
    non_parent_relative: "Non-Parent Relative",
    parent: "Parent",
    property_seller: "Property Seller",
    real_estate_agent: "Real Estate Agent",
    unrelated_friend: "Unrelated Friend",
    liquid_asset: "Liquid Asset",
    non_liquid_asset: "Non-Liquid Asset",
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FM: "Federated States Of Micronesia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    MP: "Northern Mariana Islands",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
    Afghanistan: "Afghanistan",
    ÅlandIslands: "Åland Islands",
    Albania: "Albania",
    Algeria: "Algeria",
    AmericanSamoa: "American Samoa",
    Andorra: "Andorra",
    Angola: "Angola",
    Anguilla: "Anguilla",
    Antarctica: "Antarctica",
    AntiguaandBarbuda: "Antigua and Barbuda",
    Argentina: "Argentina",
    Armenia: "Armenia",
    Aruba: "Aruba",
    Australia: "Australia",
    Austria: "Austria",
    Azerbaijan: "Azerbaijan",
    Bahamas: "Bahamas",
    Bahrain: "Bahrain",
    Bangladesh: "Bangladesh",
    Barbados: "Barbados",
    Belarus: "Belarus",
    Belgium: "Belgium",
    Belize: "Belize",
    Benin: "Benin",
    Bermuda: "Bermuda",
    Bhutan: "Bhutan",
    Bolivia: "Bolivia",
    BosniaAndHerzegovina: "Bosnia and Herzegovina",
    Botswana: "Botswana",
    BouvetIsland: "Bouvet Island",
    Brazil: "Brazil",
    BritishIndianOceanTerritory: "British Indian Ocean Territory",
    BruneiDarussalam: "Brunei Darussalam",
    Bulgaria: "Bulgaria",
    BurkinaFaso: "Burkina Faso",
    Burundi: "Burundi",
    Cambodia: "Cambodia",
    Cameroon: "Cameroon",
    Canada: "Canada",
    CapeVerde: "Cape Verde",
    CaymanIslands: "Cayman Islands",
    CentralAfricanRepublic: "Central African Republic",
    Chad: "Chad",
    Chile: "Chile",
    China: "China",
    ChristmasIsland: "Christmas Island",
    CocosKeelingIslands: "Cocos (Keeling) Islands",
    Colombia: "Colombia",
    Comoros: "Comoros",
    Congo: "Congo",
    CongoTheDemocraticRepublicOfThe: "Congo, The Democratic Republic of The",
    CookIslands: "Cook Islands",
    CostaRica: "Costa Rica",
    CoteDivoire: "Cote D'ivoire",
    Croatia: "Croatia",
    Cuba: "Cuba",
    Cyprus: "Cyprus",
    Czechia: "Czechia",
    Denmark: "Denmark",
    Djibouti: "Djibouti",
    Dominica: "Dominica",
    DominicanRepublic: "Dominican Republic",
    Ecuador: "Ecuador",
    Egypt: "Egypt",
    ElSalvador: "El Salvador",
    EquatorialGuinea: "Equatorial Guinea",
    Eritrea: "Eritrea",
    Estonia: "Estonia",
    Ethiopia: "Ethiopia",
    FalklandIslandsMalvinas: "Falkland Islands (Malvinas)",
    FaroeIslands: "Faroe Islands",
    Fiji: "Fiji",
    Finland: "Finland",
    France: "France",
    FrenchGuiana: "French Guiana",
    FrenchPolynesia: "French Polynesia",
    FrenchSouthernTerritories: "French Southern Territories",
    Gabon: "Gabon",
    Gambia: "Gambia",
    Georgia: "Georgia",
    Germany: "Germany",
    Ghana: "Ghana",
    Gibraltar: "Gibraltar",
    Greece: "Greece",
    Greenland: "Greenland",
    Grenada: "Grenada",
    Guadeloupe: "Guadeloupe",
    Guam: "Guam",
    Guatemala: "Guatemala",
    Guernsey: "Guernsey",
    Guinea: "Guinea",
    Guineabissau: "Guinea-bissau",
    Guyana: "Guyana",
    Haiti: "Haiti",
    HeardIslandAndMcdonaldIslands: "Heard Island and Mcdonald Islands",
    HolySeeVaticanCityState: "Holy See (Vatican City State)",
    Honduras: "Honduras",
    HongKong: "Hong Kong",
    Hungary: "Hungary",
    Iceland: "Iceland",
    India: "India",
    Indonesia: "Indonesia",
    IranIslamicRepublicOf: "Iran, Islamic Republic of",
    Iraq: "Iraq",
    Ireland: "Ireland",
    IsleOfMan: "Isle of Man",
    Israel: "Israel",
    Italy: "Italy",
    Jamaica: "Jamaica",
    Japan: "Japan",
    Jersey: "Jersey",
    Jordan: "Jordan",
    Kazakhstan: "Kazakhstan",
    Kenya: "Kenya",
    Kiribati: "Kiribati",
    KoreaDemocraticPeoplesRepublicOf: "Korea, Democratic People's Republic of",
    KoreaRepublicOf: "Korea, Republic of",
    Kuwait: "Kuwait",
    Kyrgyzstan: "Kyrgyzstan",
    LaoPeoplesDemocraticRepublic: "Lao People's Democratic Republic",
    Latvia: "Latvia",
    Lebanon: "Lebanon",
    Lesotho: "Lesotho",
    Liberia: "Liberia",
    LibyanArabJamahiriya: "Libyan Arab Jamahiriya",
    Liechtenstein: "Liechtenstein",
    Lithuania: "Lithuania",
    Luxembourg: "Luxembourg",
    Macao: "Macao",
    MacedoniaTheFormerYugoslavRepublicOf:
      "Macedonia, The Former Yugoslav Republic of",
    Madagascar: "Madagascar",
    Malawi: "Malawi",
    Malaysia: "Malaysia",
    Maldives: "Maldives",
    Mali: "Mali",
    Malta: "Malta",
    MarshallIslands: "Marshall Islands",
    Martinique: "Martinique",
    Mauritania: "Mauritania",
    Mauritius: "Mauritius",
    Mayotte: "Mayotte",
    Mexico: "Mexico",
    MicronesiaFederatedStatesOf: "Micronesia, Federated States of",
    MoldovaRepublicOf: "Moldova, Republic of",
    Monaco: "Monaco",
    Mongolia: "Mongolia",
    Montenegro: "Montenegro",
    Montserrat: "Montserrat",
    Morocco: "Morocco",
    Mozambique: "Mozambique",
    Myanmar: "Myanmar",
    Namibia: "Namibia",
    Nauru: "Nauru",
    Nepal: "Nepal",
    Netherlands: "Netherlands",
    NetherlandsAntilles: "Netherlands Antilles",
    NewCaledonia: "New Caledonia",
    NewZealand: "New Zealand",
    Nicaragua: "Nicaragua",
    Niger: "Niger",
    Nigeria: "Nigeria",
    Niue: "Niue",
    NorfolkIsland: "Norfolk Island",
    NorthernMarianaIslands: "Northern Mariana Islands",
    Norway: "Norway",
    Oman: "Oman",
    Pakistan: "Pakistan",
    Palau: "Palau",
    PalestinianTerritoryOccupied: "Palestinian Territory, Occupied",
    Panama: "Panama",
    PapuaNewGuinea: "Papua New Guinea",
    Paraguay: "Paraguay",
    Peru: "Peru",
    Philippines: "Philippines",
    Pitcairn: "Pitcairn",
    Poland: "Poland",
    Portugal: "Portugal",
    PuertoRico: "Puerto Rico",
    Qatar: "Qatar",
    Reunion: "Reunion",
    Romania: "Romania",
    RussianFederation: "Russian Federation",
    Rwanda: "Rwanda",
    SaintHelena: "Saint Helena",
    SaintKittsAndNevis: "Saint Kitts and Nevis",
    SaintLucia: "Saint Lucia",
    SaintPierreAndMiquelon: "Saint Pierre and Miquelon",
    SaintVincentAndTheGrenadines: "Saint Vincent and The Grenadines",
    Samoa: "Samoa",
    SanMarino: "San Marino",
    SaoTomeAndPrincipe: "Sao Tome and Principe",
    SaudiArabia: "Saudi Arabia",
    Senegal: "Senegal",
    Serbia: "Serbia",
    Seychelles: "Seychelles",
    SierraLeone: "Sierra Leone",
    Singapore: "Singapore",
    Slovakia: "Slovakia",
    Slovenia: "Slovenia",
    SolomonIslands: "Solomon Islands",
    Somalia: "Somalia",
    SouthAfrica: "South Africa",
    SouthGeorgiaAndTheSouthSandwichIslands:
      "South Georgia and The South Sandwich Islands",
    Spain: "Spain",
    SriLanka: "Sri Lanka",
    Sudan: "Sudan",
    Suriname: "Suriname",
    SvalbardAndJanMayen: "Svalbard and Jan Mayen",
    Swaziland: "Swaziland",
    Sweden: "Sweden",
    Switzerland: "Switzerland",
    SyrianArabRepublic: "Syrian Arab Republic",
    TaiwanProvinceOfChina: "Taiwan, Province of China",
    Tajikistan: "Tajikistan",
    TanzaniaUnitedRepublicOf: "Tanzania, United Republic of",
    Thailand: "Thailand",
    Timorleste: "Timor-leste",
    Togo: "Togo",
    Tokelau: "Tokelau",
    Tonga: "Tonga",
    TrinidadAndTobago: "Trinidad and Tobago",
    Tunisia: "Tunisia",
    Turkey: "Turkey",
    Turkmenistan: "Turkmenistan",
    TurksAndCaicosIslands: "Turks and Caicos Islands",
    Tuvalu: "Tuvalu",
    Uganda: "Uganda",
    Ukraine: "Ukraine",
    UnitedArabEmirates: "United Arab Emirates",
    UnitedKingdom: "United Kingdom",
    USA: "United States",
    UnitedStatesMinorOutlyingIslands: "United States Minor Outlying Islands",
    Uruguay: "Uruguay",
    Uzbekistan: "Uzbekistan",
    Vanuatu: "Vanuatu",
    Venezuela: "Venezuela",
    VietNam: "Viet Nam",
    VirginIslandsBritish: "Virgin Islands, British",
    VirginIslandsUS: "Virgin Islands, U.S.",
    WallisAndFutuna: "Wallis and Futuna",
    WesternSahara: "Western Sahara",
    Yemen: "Yemen",
    Zambia: "Zambia",
    Zimbabwe: "Zimbabwe",
    countryCodes: {
      AF: "Afghanistan",
      AX: "AlandIslands",
      AL: "Albania",
      DZ: "Algeria",
      AS: "American Samoa",
      AD: "American Samoa",
      AO: "Angola",
      AI: "Anguilla",
      AQ: "Antarctica",
      AG: "Antigua and Barbuda",
      AR: "Argentina",
      AM: "Armenia",
      AW: "Aruba",
      AU: "Australia",
      AT: "Austria",
      AZ: "Azerbaijan",
      BS: "Bahamas",
      BH: "Bahrain",
      BD: "Bangladesh",
      BB: "Barbados",
      BY: "Belarus",
      BE: "Belgium",
      BZ: "Belize",
      BJ: "Benin",
      BM: "Bermuda",
      BT: "Bhutan",
      BO: "Bolivia",
      BA: "Bosnia and Herzegovina",
      BW: "Botswana",
      BV: "Bouvet Island",
      BR: "Brazil",
      IO: "British Indian Ocean Territory",
      BN: "Brunei Darussalam",
      BG: "Bulgaria",
      BF: "Burkina Faso",
      BI: "Burundi",
      KH: "Cambodia",
      CM: "Cameroon",
      CA: "Canada",
      CV: "Cape Verde",
      KY: "Cayman Islands",
      CF: "Central African Republic",
      TD: "Chad",
      CL: "Chile",
      CN: "China",
      CX: "Christmas Island",
      CC: "Cocos (Keeling) Islands",
      CO: "Colombia",
      KM: "Comoros",
      CG: "Congo",
      CD: "Congo, The Democratic Republic of the",
      CK: "Cook Islands",
      CR: "Costa Rica",
      CI: "Cote D'Ivoire",
      HR: "Croatia",
      CU: "Cuba",
      CY: "Cyprus",
      CZ: "Czech Republic",
      DK: "Denmark",
      DJ: "Djibouti",
      DM: "Dominica",
      DO: "Dominican Republic",
      EC: "Ecuador",
      EG: "Egypt",
      SV: "El Salvador",
      GQ: "Equatorial Guinea",
      ER: "Eritrea",
      EE: "Estonia",
      ET: "Ethiopia",
      FK: "Falkland Islands (Malvinas)",
      FO: "Faroe Islands",
      FJ: "Fiji",
      FI: "Finland",
      FR: "France",
      GF: "French Guiana",
      PF: "French Polynesia",
      TF: "French Southern Territories",
      GA: "Gabon",
      GM: "Gambia",
      GE: "Georgia",
      DE: "Germany",
      GH: "Ghana",
      GI: "Gibraltar",
      GR: "Greece",
      GL: "Greenland",
      GD: "Grenada",
      GP: "Guadeloupe",
      GU: "Guam",
      GT: "Guatemala",
      GG: "Guernsey",
      GN: "Guinea",
      GW: "Guinea-Bissau",
      GY: "Guyana",
      HT: "Haiti",
      HM: "Heard Island and Mcdonald Islands",
      VA: "Holy See (Vatican City State)",
      HN: "Honduras",
      HK: "Hong Kong",
      HU: "Hungary",
      IS: "Iceland",
      IN: "India",
      ID: "Indonesia",
      IR: "Iran, Islamic Republic Of",
      IQ: "Iraq",
      IE: "Ireland",
      IM: "Isle of Man",
      IL: "Israel",
      IT: "Italy",
      JM: "Jamaica",
      JP: "Japan",
      JE: "Jersey",
      JO: "Jordan",
      KZ: "Kazakhstan",
      KE: "Kenya",
      KI: "Kiribati",
      KP: "Korea, Democratic People's Republic of",
      KR: "Korea, Republic of",
      KW: "Kuwait",
      KG: "Kyrgyzstan",
      LA: "Lao People's Democratic Republic",
      LV: "Latvia",
      LB: "Lebanon",
      LS: "Lesotho",
      LR: "Liberia",
      LY: "Libyan Arab Jamahiriya",
      LI: "Liechtenstein",
      LT: "Lithuania",
      LU: "Luxembourg",
      MO: "Macao",
      MK: "Macedonia, The Former Yugoslav Republic of",
      MG: "Madagascar",
      MW: "Malawi",
      MY: "Malaysia",
      MV: "Maldives",
      ML: "Mali",
      MT: "Malta",
      MH: "Marshall Islands",
      MQ: "Martinique",
      MR: "Mauritania",
      MU: "Mauritius",
      YT: "Mayotte",
      MX: "Mexico",
      FM: "Micronesia, Federated States of",
      MD: "Moldova, Republic of",
      MC: "Monaco",
      MN: "Mongolia",
      ME: "Montenegro",
      MS: "Montserrat",
      MA: "Morocco",
      MZ: "Mozambique",
      MM: "Myanmar",
      NA: "Namibia",
      NR: "Nauru",
      NP: "Nepal",
      NL: "Netherlands",
      AN: "Netherlands Antilles",
      NC: "New Caledonia",
      NZ: "New Zealand",
      NI: "Nicaragua",
      NE: "Niger",
      NG: "Nigeria",
      NU: "Niue",
      NF: "Norfolk Island",
      MP: "Northern Mariana Islands",
      NO: "Norway",
      OM: "Oman",
      PK: "Pakistan",
      PW: "Palau",
      PS: "Palestinian Territory, Occupied",
      PA: "Panama",
      PG: "Papua New Guinea",
      PY: "Paraguay",
      PE: "Peru",
      PH: "Philippines",
      PN: "Pitcairn",
      PL: "Poland",
      PT: "Portugal",
      PR: "Puerto Rico",
      QA: "Qatar",
      RE: "Reunion",
      RO: "Romania",
      RU: "Russian Federation",
      RW: "RWANDA",
      SH: "Saint Helena",
      KN: "Saint Kitts and Nevis",
      LC: "Saint Lucia",
      PM: "Saint Pierre and Miquelon",
      VC: "Saint Vincent and the Grenadines",
      WS: "Samoa",
      SM: "San Marino",
      ST: "Sao Tome and Principe",
      SA: "Saudi Arabia",
      SN: "Senegal",
      RS: "Serbia",
      SC: "Seychelles",
      SL: "Sierra Leone",
      SG: "Singapore",
      SK: "Slovakia",
      SI: "Slovenia",
      SB: "Solomon Islands",
      SO: "Somalia",
      ZA: "South Africa",
      GS: "South Georgia and the South Sandwich Islands",
      ES: "Spain",
      LK: "Sri Lanka",
      SD: "Sudan",
      SR: "Suriname",
      SJ: "Svalbard and Jan Mayen",
      SZ: "Swaziland",
      SE: "Sweden",
      CH: "Switzerland",
      SY: "Syrian Arab Republic",
      TW: "Taiwan, Province of China",
      TJ: "Tajikistan",
      TZ: "Tanzania, United Republic of",
      TH: "Thailand",
      TL: "Timor-Leste",
      TG: "Togo",
      TK: "Tokelau",
      TO: "Tonga",
      TT: "Trinidad and Tobago",
      TN: "Tunisia",
      TR: "Turkey",
      TM: "Turkmenistan",
      TC: "Turks and Caicos Islands",
      TV: "Tuvalu",
      UG: "Uganda",
      UA: "Ukraine",
      AE: "United Arab Emirates",
      GB: "United Kingdom",
      US: "United States",
      UM: "United States Minor Outlying Islands",
      UY: "Uruguay",
      UZ: "Uzbekistan",
      VU: "Vanuatu",
      VE: "Venezuela",
      VN: "Viet Nam",
      VG: "Virgin Islands, British",
      VI: "Virgin Islands, U.S.",
      WF: "Wallis and Futuna",
      EH: "Western Sahara",
      YE: "Yemen",
      ZM: "Zambia",
      ZW: "Zimbabwe",
    },
    single: "Single Family Home",
    condominium: "Condominium",
    townhouse: "Townhouse",
    cooperative: "Cooperative",
    highRise: "High Rise Condominium",
    manufactured: "Manufactured Home",
    plannedUnit: "Planned Unit Development",
    detached: "Detached Condo",
    oneunit: "1 Unit Property",
    twounit: "2 Unit Property",
    threeunit: "3 Unit Property",
    fourunit: "4 Unit Property",
    mobilehome: "Mobile Home",
    lowerInterest: "Lower interest rates",
    cashOut: "Cash-Out for Home Improvement",
    consolidate: "Cash-Out for Debt Consolidation",
    limited: "Limited Cash-Out",
    borrower: "Me",
    coborrower: "My co-borrower",
    both: "Both",
    borrowerFormal: "Borrower",
    coborrowerFormal: "Co-borrower",
    bothFormal: "Both",
    income: "Income",
    loss: "Loss",
    hsged: "High school diploma or GED",
    associate: "Associate's degree",
    bachelors: "Bachelor's degree",
    masters: "Master's degree",
    doctorate: "Doctorate degree",
    none: "None of the above",
    internet: "Internet",
    phone: "Phone",
    face_to_face: "Face-to-Face",
    mail: "Mail",
    hfa_preferred: "HFA Preferred",
    hfa_preferred_risk_sharing: "HFA Preferred Risk Sharing",
    home_ready: "Home Ready",
    my_community_mortgage: "My Community Mortgage",
    deferred_forgiven: "Deferred Fully Forgiven",
    deferred_not_forgiven: "Deferred Not Fully Forgiven",
    non_deferred: "Non-Deferred",
    plannedUnitDevelopment: "Planned Unit Development (PUD)",
    propertyNotLocatedInAProject: "Property is not located in a project",
    single_closing: "Single-Closing",
    two_closing: "Two-Closing",
    no_cash_out: "No Cash Out",
    limited_cash_out: "Limited Cash Out",
    cash_out: "Cash Out",
    full_documentation: "Full Documentation",
    interest_rate_reduction: "Interest Rate Reduction",
    streamlined_without_appraisal: "Streamlined without Appraisal",
    fee_simple: "Fee Simple",
    leasehold: "Leasehold",
    not_applicable: "Not Applicable",
    sole_ownership: "Sole Ownership",
    life_estate: "Life Estate",
    tenancy_in_common: "Tenancy in Common",
    joint_tenancy: "Joint Tenancy with Right of Survivorship",
    tenancy_by_the_entirety: "Tenancy by the Entirety",
    living_trust: "Title Will be Held by an Inter Vivos (Living) Trust",
    land_trust: "Title Will be Held by a Land Trust",
    fee_simple_on_a_reservation: "Fee Simple On a Reservation",
    individual_trust_land: "Individual Trust Land (Allotted/Restricted)",
    tribal_trust_land_on_a_reservation: "Tribal Trust Land On a Reservation",
    tribal_trust_land_off_reservation: "Tribal Trust Land Off a Reservation",
    alaska_native_corporation_land: "Alaska Native Corporation Land",
    fixed: "Fixed Rate",
    arm: "Adjustable Rate",
    attached_property: "Attached",
    detached_property: "Detached",
    garden_project: "Garden Project",
    highrise_project: "High-Rise Project",
    midrise_project: "Mid-Rise Project",
    townhouse_rowhouse: "Townhose/Rowhouse",
    other_project: "Other",
    english: "English",
    chinese: "Chinese",
    korean: "Korean",
    spanish: "Spanish",
    tagalog: "Tagalog",
    vietnamese: "Vietnamese",
    declined: "Do not wish to provide",
    single_wide: "Single-Wide",
    multi_wide: "Multi-Wide",
    education_format_face_to_face: "Attended Workshop in Person",
    education_format_internet: "Completed Web-Based Workshop",
    counseling_format_face_to_face: "Face to Face",
    counseling_format_telephone: "Telephone",
    counseling_format_internet: "Internet",
    counseling_format_hybrid: "Hybrid",
  },
  navigation: {
    continue: "Continue",
    register: "Finish Setup",
    previous: "Previous",
  },
  tooltips: {
    mixedUse:
      "A Mixed-Use Property is a property that has a business use in addition to a residential use. Neither retail space within a multi-unit property, nor use of a room in the property as a home office are considered Mixed-Use Properties.",
    manufactured: "A factory built dwelling built on a permanent chassis.",
    verifyIncome: "Verify all income entries above to continue",
  },
  sideBar: {
    register: "Register",
    loanPurpose: "Loan Purpose",
    welcome: "Welcome",
    yourInfo: "Your Info",
    intro: "Intro",
    personalInfo: "Personal Info",
    contactInfo: "Contact Info",
    dependents: "Dependents",
    addressHistory: "Address History",
    addressHistoryOverview: "Address History Overview",
    militaryService: "Military Service",
    employment: "Employment History",
    employmentOverview: "Employment Overview",
    otherIncome: "Other Income",
    otherIncomeOverview: "Other Income Overview",
    verifyIncome: "Verify Income",
    declarations: "Declarations",
    demographics: "Demographics",
    homeownershipEducation: "Homeownership Education",
    languagePreference: "Language Preference",
    additionalQuestions: "Additional Questions",
    review: "Review",
    coBorrowerInfo: "Co-Borrower Info",
    addCoborrower: "Add Co-Borrower",
    assets: "Assets",
    addAssets: "Add Assets",
    assetsOverview: "Assets Overview",
    assetsCreditsOverview: "Assets & Credits Overview",
    liabilities: "Liabilities",
    addLiabilities: "Add Liabilities",
    liabilitiesOverview: "Liabilities Overview",
    realEstate: "Real Estate",
    addRealEstate: "Add Real Estate",
    realEstateOverview: "Real Estate Overview",
    loanInfo: "Loan Info",
    loanPurpose: "Loan Purpose",
    propertyInfo: "Property Info",
    realtorInfo: "Realtor Info",
    referralPartner: "Referral Info",
    addGifts: "Add Gifts or Grants",
    giftsOverview: "Gifts or Grants Overview",
    reviewSubmit: "Review & Submit",
    submit: "Submit",
  },
  creditCheck: {
    // Dont need this in other languages since only LOs see this text
    heading: "Credit Check",
    method: "Method of Consent Given",
    methodRequired: "Credit check method of consent given is required.",
    date: "Date of Consent Given",
  },
  patchError: {
    heading: "We're having some issues",
    subHeadingOne: "A problem may have occurred with this page. Try clicking",
    subHeadingTwo: "Reload Page",
    subHeadingThree: "below to continue applying.",
  },
  saveIndicator: {
    saving: "Saving Changes",
    doneSaving: "All Changes Saved",
    savingError: "Error Saving Changes",
  },
  copilot: {
    asset: "asset",
    pickAssetOne: "is currently editing the",
    liability: "liability",
    pickLiabilityOne: "is currently editing the",
    income: "income",
    pickIncomeOne: "is currently editing the",
    realEstate: "real estate",
    pickRealEstateOne: "is currently editing",
    employment: "Employment History",
    pickEmploymentOne: "is currently editing",
    pickGiftOne: "is currently editing a",
    offline: "offline",
  },
  lenderLoanInfo: {
    isLivingInACommunityPropertyState:
      "Does at least one borrower live in a community property state?",
    isPropertyInACommunityPropertyState:
      "Is the property in a community property state?",
    projectType: "Project Type",
    isConversionOfContractForDeedOrLandContract:
      "Is this a conversion of contract for deed or land contract?",
    isRenovation: "Is this a renovation?",
    isConstructionConversionOrConstructionToPermanent:
      "Is this a construction-conversion or a construction-to-permanent transaction?",
    closingType: "Closing Type",
    lotAcquired: "Lot Acquired Date",
    originalCostOfLot: "Original Cost of Lot",
    constructionOrImprovementCosts: "Construction/Improvement Costs",
    isRefinance: "Is this a refinance?",
    refinanceType: "Refinance Type",
    refinanceProgram: "Refinance Program",
    refinanceProgramOtherText: "Explain",
    isMortgageUsedToFinanceEnergyRelatedImprovements:
      "Will the mortgage loan be used to finance energy-related improvements?",
    isCleanEnergyLienPaidForThroughPropertyTaxes:
      "Is the property currently subject to a lien that could take priority over the first mortgage lien, such as a clean energy lien paid for through property taxes (e.g. the Property Assessed Clean Energy Program)?",
    newTitle: "Title to the property will be held in the following names",
    currentTitle:
      "Title to the property is currently held in the following names",
    estateType: "Estate Will Be Held In",
    leaseholdExpiration: "Leasehold Expiration Date",
    titleOwnershipType: "Manner in Which Title Will be Held",
    trustType: "Trust Information",
    indianCountryTrustType: "Indian Country Land Tenure",
    mortgageType: "Mortgage Type",
    mortgageTypeOtherText: "Explain",
    amortizationType: "Amortization Type",
    initialPeriodPriorToFirstAdjustment:
      "Initial Period Prior to First Adjustment (In Months)",
    subsequentAdjustmentPeriod: "Subsequent Adjustment Period (In Months)",
    amortizationTypeOtherText: "Explain",
    noteRate: "Note Rate",
    loanTerm: "Loan Term (In Months)",
    lienType: "Mortgage Lien Type",
    prepaidItemsEstimatedAmount: "Estimated Prepaid Items",
    loanFeatures: "Select the loan features that apply.",
    isBalloon: "Balloon",
    balloonTerm: "Balloon Term (In Months)",
    isInterestOnly: "Interest Only",
    interestOnlyTerm: "Interest Only Term (In Months)",
    isNegativeAmortization: "Negative Amortization",
    isPrepaymentPenalty: "Prepayment Penalty",
    prepaymentPenaltyTerm: "Prepayment Penalty Term (In Months)",
    isTemporaryInterestRateBuydown: "Temporary Interest Rate Buydown",
    initialBuydownRate: "Initial Buydown Rate",
    isOther: "Are there any other loan features?",
    otherText: "Explain",
    numberOfMonths: "Number of Months",
    firstMortgage: "First Mortgage (P & I)",
    subordinateLiens: "Subordinate Lien(s) (P & I)",
    homeownersInsurance: "Homeowner’s Insurance",
    supplementalPropertyInsurance: "Supplemental Property Insurance",
    propertyTaxes: "Property Taxes",
    mortgageInsurance: "Mortgage Insurance",
    associationOrProjectDues: "Association/Project Dues (Condo, Co-Op, PUD)",
    other: "Other Monthly Payments",
    total: "Total",
    projectDesignType: "Project Design Type",
    affordableLendingQuestionSingular:
      "Does the borrower qualify for the Freddie Mac Refi Possible program?",
    affordableLendingQuestion:
      "Does the borrowers qualify for the Freddie Mac Refi Possible program?",
  },
  loanOriginatorInfo: {
    loanOriginatorOrganizationName: "Loan Originator Organization Name",
    loanOriginatorOrganizationNmlsrId: "Organization NMLS ID",
    loanOriginatorOrganizationStateLicenseId: "Organization State License ID",
    loanOriginatorFirstName: "Loan Originator First Name",
    loanOriginatorLastName: "Loan Originator Last Name",
    loanOriginatorNmlsrId: "Loan Originator NMLS ID",
    loanOriginatorStateLicenseId: "Loan Originator State License ID",
  },
};
