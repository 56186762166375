// These must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { isSafariIframe } from "../src/assets/utils/isSafariIframe";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

isSafariIframe().then(function (res) {
  ReactDOM.render(<App />, document.getElementById("root"));
});
