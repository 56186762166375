import React, { Component } from "react";

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    componentDidMount() {
      importComponent()
        .then(cmp => {
          // console.log( 'component loaded correctly! ')
          this.setState({ component: cmp.default })
        })
        .catch(() => {
          // console.log('there was a problem loading the component, doing page refresh')
          // if there was an error, just refresh the page
          window.location.reload(true)
        })
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}