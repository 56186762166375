import { initialTenOhThreeValues } from "./initialTenOhThreeValues";
import _ from "lodash";

const tenOhThreeReducer = (state = initialTenOhThreeValues, action) => {
  switch (action.type) {
    case "MASTER_UPDATE":
      let localCopy = _.cloneDeep(state);
      _.set(localCopy, action.payload.dataMap, action.payload.value);
      return localCopy;
    case "SET_AUTH_TOKEN":
      return {
        ...state,
        authToken: action.payload,
      };
    case "SET_REFFERER_ROUTE":
      return {
        ...state,
        refRoute: action.payload,
      };
    case "SET_LOAN_ID":
      return {
        ...state,
        loanId: action.payload,
      };
    case "SET_BORROWER_PAIR_ID":
      return {
        ...state,
        borrowerPairId: action.payload,
      };
    case "SET_USER_INFO":
      return {
        ...state,
        userInfo: action.payload,
      };
    // case 'SET_PROSPECT_ID':
    //     return {
    //         ...state,
    //         prospectId : action.payload
    //     }
    case "SET_CONFIG":
      return {
        ...state,
        config: action.payload,
      };
    case "SET_QUICK_ASSIST_CONTEXT":
      return {
        ...state,
        quickAssistContext: action.payload,
      };
    case "SET_NUMBER_OF_BORROWERS":
      return {
        ...state,
        numberOfBorrowers: action.payload,
      };
    case "SET_USER_TYPE":
      return {
        ...state,
        userType: action.payload,
      };
    case "SET_URL_PREFIX":
      return {
        ...state,
        urlPrefix: action.payload,
      };
    case "SET_QUERY_PARAMS":
      return {
        ...state,
        urlQueryParams: action.payload,
      };
    case "SET_CURRENT_PATH":
      return {
        ...state,
        currentPath: action.payload,
      };
    case "SET_INITIALIZED":
      return {
        ...state,
        initialized: action.payload,
      };
    // case 'ADD_COMBINED_ASSET_LIST':
    //     let addAsset = _.cloneDeep( state )
    //     let assetIndex = 0
    //     if( addAsset.combinedAssetList.length ){
    //         assetIndex = addAsset.combinedAssetList.length
    //     }
    //     _.set( addAsset, `combinedAssetList[${assetIndex}]`, action.payload )
    //     return addAsset
    // case 'REMOVE_COMBINED_ASSET_LIST':
    //     let deleteAsset = _.cloneDeep( state )
    //     let filteredDelete = deleteAsset.combinedAssetList.filter( i => {
    //         if( i.key !== action.payload ){
    //             return i
    //         }
    //     })
    //     _.set( deleteAsset, 'combinedAssetList', filteredDelete )
    //     return deleteAsset
    case "SET_CURRENT_ASSET":
      return {
        ...state,
        currentAsset: action.payload,
      };
    // case 'ADD_COMBINED_LIABILITY_LIST':
    //     let addLiability = _.cloneDeep( state )
    //     let liabilityIndex = 0
    //     if( addLiability.combinedLiabilityList.length ){
    //         liabilityIndex = addLiability.combinedLiabilityList.length
    //     }
    //     _.set( addLiability, `combinedLiabilityList[${liabilityIndex}]`, action.payload )
    //     return addLiability
    // case 'REMOVE_COMBINED_LIABILITY_LIST':
    //     let deleteLiability = _.cloneDeep( state )
    //     let filteredLiability = deleteLiability.combinedLiabilityList.filter( i => {
    //         if( i.key !== action.payload ){
    //             return i
    //         }
    //     })
    //     _.set( deleteLiability, 'combinedLiabilityList', filteredLiability )

    //     return deleteLiability
    case "SET_CURRENT_LIABILITY":
      return {
        ...state,
        currentLiability: action.payload,
      };
    case "SET_CURRENT_REAL_ESTATE":
      return {
        ...state,
        currentRealEstate: action.payload,
      };
    case "SET_CURRENT_EMPLOYMENT":
      return {
        ...state,
        currentEmployment: action.payload,
      };
    case "SET_SHOW_VERIFY_INCOME_LINK":
      return {
        ...state,
        showVerifyIncomeLink: action.payload,
      };
    case "SET_CURRENT_GIFT":
      return {
        ...state,
        currentGift: action.payload,
      };
    case "SET_NEXT_PAGE":
      return {
        ...state,
        nextPage: action.payload,
      };
    case "SET_PREV_PAGE":
      return {
        ...state,
        prevPage: action.payload,
      };
    case "SET_CURRENT_OTHER_INCOME":
      return {
        ...state,
        currentOtherIncome: action.payload,
      };
    case "REMOVE_REQUIRED_ERRORS":
      let filteredErrors = [];
      state.requiredErrors.map((i) => {
        let match = false;
        action.payload.map((j) => {
          if (i.configDataMap === j.configDataMap) {
            match = true;
          }
          return;
        });
        if (!match) {
          filteredErrors.push(i);
        }
        return;
      });

      return {
        ...state,
        requiredErrors: filteredErrors,
      };
    case "SET_REQUIRED_ERRORS":
      let errorsToSave = [...state.requiredErrors];
      // dont add duplicates
      action.payload.map((error) => {
        let hasMatch = false;
        state.requiredErrors.map((existingError) => {
          if (
            error.configDataMap === existingError.configDataMap &&
            error.userMap === existingError.userMap
          ) {
            hasMatch = true;
          }
          return;
        });
        if (!hasMatch) {
          errorsToSave.push(error);
        }
        return;
      });
      return {
        ...state,
        requiredErrors: errorsToSave,
      };
    case "SET_EMPLOYMENT_HISTORY_CONFIRMED":
      return {
        ...state,
        employmentHistoryConfirmed: action.payload,
      };
    case "SET_GENERATE_DIFF":
      return {
        ...state,
        generateDiff: action.payload,
      };
    case "SET_GENERATE_CUSTOM_DATA_DIFF":
      return {
        ...state,
        generateCustomDataDiff: action.payload,
      };
    case "SET_FINAL_REVIEW_REDIRECT": {
      return {
        ...state,
        finalReviewRedirect: action.payload,
      };
    }
    case "SET_SHOW_FINAL_COMPLETE_MODAL":
      return {
        ...state,
        showFinalCompleteModal: action.payload,
      };
    case "SET_SHOW_ERROR_MODAL":
      return {
        ...state,
        showErrorModal: action.payload,
      };
    case "SET_SHOW_LOGOUT_ERROR_MODAL":
      return {
        ...state,
        showLogoutErrorModal: action.payload,
      };
    case "SET_SHOW_PATCH_ERROR_MODAL":
      return {
        ...state,
        showPatchErrorModal: action.payload,
      };
    case "SET_CUSTOM_DATA":
      return {
        ...state,
        customData: action.payload,
      };
    case "SET_VOA_ACCOUNTS":
      return {
        ...state,
        customData: {
          ...state.customData,
          voaAccounts: action.payload,
        },
      };
    // case 'SET_PAGE_NAV_CLICK' :
    //     return {
    //         ...state,
    //         pageNavClick : action.payload
    //     }
    case "SET_FILTERED_ROUTES":
      return {
        ...state,
        filteredRoutes: action.payload,
      };
    case "SET_CUSTOM_QUESTION_ANSWERS":
      return {
        ...state,
        customData: {
          ...state.customData,
          customFields: action.payload,
        },
      };
    case "SET_LOADING_SCREEN_VISIBLE":
      return {
        ...state,
        loadingScreenVisible: action.payload,
      };
    case "SET_LOADING_SCREEN_MESSAGE":
      return {
        ...state,
        loadingScreenMessage: action.payload,
      };
    case "SET_TEAM_LOGO":
      return {
        ...state,
        teamLogo: action.payload,
      };
    case "SET_MOBILE_MENU_VIZ":
      return {
        ...state,
        mobileMenuViz: action.payload,
      };
    case "SET_READ_ONLY":
      return {
        ...state,
        readOnly: action.payload,
      };
    case "SET_USER_VIEW":
      return {
        ...state,
        userView: action.payload,
      };
    case "SET_FULL_VIEW":
      return {
        ...state,
        fullView: action.payload,
      };
    case "SET_QUICK_ASSIST_SHOWING":
      return {
        ...state,
        quickAssistShowing: action.payload,
      };
    case "SET_NO_REALESTATE":
      return {
        ...state,
        noRealEstate: action.payload,
      };
    case "SET_COBO_DATA_BACKUP":
      return {
        ...state,
        coboDataBackup: action.payload,
      };
    case "SET_LENDER_ACTIVE_LINK":
      return {
        ...state,
        lenderActiveLink: action.payload,
      };
    case "SET_DOWNLOAD_LINKS":
      return {
        ...state,
        downloadLinks: action.payload,
      };
    case "SET_LENDER_LAYER":
      return {
        ...state,
        lenderLayer: action.payload,
      };
    case "SET_AN2_OUTPUT":
      return {
        ...state,
        an2Output: action.payload,
      };
    case "SET_BORROWER_CUSTOM_DATA":
      let updatedBorrowerCustomData = _.clone(state.customData.borrowers);
      updatedBorrowerCustomData[0] = action.payload;
      return {
        ...state,
        customData: {
          ...state.customData,
          borrowers: updatedBorrowerCustomData,
        },
      };
    case "SET_CO_BORROWER_CUSTOM_DATA":
      let updatedCoborrowerCustomData = _.clone(state.customData.borrowers);
      updatedCoborrowerCustomData[1] = action.payload;
      return {
        ...state,
        customData: {
          ...state.customData,
          borrowers: updatedCoborrowerCustomData,
        },
      };

    case "SET_SUBTITLES":
      return {
        ...state,
        showSubtitles: action.payload,
      };
    case "SET_SUBTITLE_LANGUAGE":
      return {
        ...state,
        subtitleText: action.payload,
      };
    case "SET_ADD_ADDRESS_CLICK":
      return {
        ...state,
        addAddressClick: action.payload,
      };
    case "SET_TEMPLATE_UID":
      return {
        ...state,
        templateUID: action.payload,
      };
    case "SET_LOS_LOAN_NUMBER_SET":
      return {
        ...state,
        losLoanNumberSet: action.payload,
      };
    case "SET_LENDER_EMAIL":
      return {
        ...state,
        lenderEmail: action.payload,
      };
    case "UI_UPDATE_MULTI":
      let localCopyMulti = _.cloneDeep(state);
      action.payload.map((i) => {
        _.set(localCopyMulti, i.dataMap, i.value);
      });
      return localCopyMulti;
    case "SET_SAVE_INDICATOR":
      return {
        ...state,
        saveIndicator: action.payload,
      };
    case "SET_CO_PILOT_UID":
      return {
        ...state,
        coPilotUID: action.payload,
      };
    case "DO_CO_PILOT_UPDATE":
      return {
        ...state,
        doCopilotUpdate: action.payload,
      };
    case "SET_EMAIL_IN_USE_ERROR":
      return {
        ...state,
        emailInUseError: action.payload,
      };
    case "SET_SEND_SUBMIT_SUCCESS":
      return {
        ...state,
        submitSuccess: action.payload,
      };
    case "SHOW_FOREIGN_USER_DID_SUBMIT":
      return {
        ...state,
        showForeignUserDidSubmit: action.payload,
      };
    case "SET_LOAN_ID_COPILOT":
      return {
        ...state,
        coPilotLoanId: action.payload,
      };
    case "SHOW_CO_PILOT_LIMIT_REACHED":
      return {
        ...state,
        coPilotLimitReached: action.payload,
      };
    case "SET_FORCE_COMPLETE":
      return {
        ...state,
        forceCompleted: action.payload,
      };
    case "SET_SESSION_TIMED_OUT":
      return {
        ...state,
        sessionTimedOut: action.payload,
      };
    case "SET_RESTRICT_SMS_CONSENT":
      if (!action.payload?.hasDeniedConsent) {
        return {
          ...state,
          textMessageConsent: {
            restrict: false,
          },
        };
      }
      return {
        ...state,
        textMessageConsent: {
          restrict: true,
          tooltipText: `You previously opted out of text messages by replying STOP to ${action.payload.stopRecipient}. To opt back in, reply START to ${action.payload.updateConsentRecipient}.`,
        },
      };

    case "SET_UNDER_CONTRACT":
      return {
        ...state,
        isUnderContract: action.payload,
      };

    default:
      return state;
  }
};

export default tenOhThreeReducer;
