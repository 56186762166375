import _ from "lodash";

export const addressObject = {
  fullAddress: "", // 5391 W 73rd Ave. Westminster, CO  80003
  street: "", // "5391 W 73rd Ave",
  unit: "", // unit #
  city: "", // "Westminster",
  stateOrProvince: "", // "CO",
  postalCode: "", // "80003",
  moveInDate: "", // "2018-06",
  residencyBasis: "", // "own"
  country: "", // "US" "CA"
  monthlyRent: 0,
  monthlyMortgage: 0,
  monthlyFinancing: 0,
  hazardInsurance: 0,
  mortgageInsurance: 0,
  realEstateTaxes: 0,
  hoaDues: 0,
  otherExpenses: 0,
};

// export const militaryServiceObject = {
//     "currentlyServing": false,
//     "currentlyRetired": false,
//     "nonActivated": false,
//     "servingSpouse": false,
// }

export const employmentIncomeObject = {
  name: "", // "Foo Inc",
  title: "",
  phone: "",
  address: {
    ..._.cloneDeep(addressObject),
  },
  isCurrent: false,
  startDate: "", // "2017-10",
  endDate: "", // "2018-04",
  isEmployerPartyToLoan: false,
  experienceYears: "",
  experienceMonths: "",
  selfEmployed: false,
  atLeast25PctOwnership: false,
  monthlyIncomeOrLoss: 0,
  monthlyIncome: {
    base: 0, // 5000
    overtime: 0,
    bonus: 0,
    commission: 0,
    militaryEntitlement: 0,
    other: 0,
  },
};

export const otherIncomeObject = {
  name: "", // "side hustle",
  type: "", // "other",
  amount: 0, // 300
};

export const assetsAccountObject = {
  name: "", // "Main Checking",
  type: "", // "checking",
  institutionName: "", // "Chase",
  accountNumber: "", //"12345678",
  value: 0, //10000
};

export const assetsOtherObject = {
  type: "",
  name: "", // "Picasso",
  value: "", // 100000
};

export const liabilityAccountObject = {
  name: "", // "Main Credit Card",
  type: "", // "revolving",
  institutionName: "", // "Chase",
  accountNumber: "", // "12345678",
  balance: 0, // 2000,
  toBePaidOff: false,
  monthlyPayment: 0, // 200
};

export const liabilityOtherObject = {
  name: "", // "Child Support",
  type: "", // "child_support",
  monthlyPayment: 0, // 100000
};

export const loanObject = {
  creditorName: null,
  monthlyPayment: null,
  creditLimit: null,
  accountNumber: null,
  type: null,
  balance: null,
  toBePaidOff: false,
};

export const realEstateObject = {
  // "owner" : "",
  address: {
    ..._.cloneDeep(addressObject),
  },
  propertyValue: null, // 200000,
  status: null, // "sold",
  monthlyRentalIncome: 0,
  netMonthlyRentalIncome: 0,
  propertyLoans: [
    // ...loanObject,
    // ...loanObject,
    // ...loanObject,
  ],
  monthlyExpenses: {
    otherFinancing: 0,
    hazardInsurance: 0, // 100,
    mortgageInsurance: 0, // 300,
    taxes: 0, // 300,
    hoa: 0, // 100,
    other: 0,
    total: 0, //800
  },
};

export const borrowerObject = {
  id: null,
  personalInfo: {
    alternateNames: [
      // {
      //   "firstName": null,
      //   "middleName": null,
      //   "lastName": null,
      //   "suffix": ""
      // }
    ],
    dateOfBirth: null,
    citizenship: null,
    email: null,
    // "highestLevelOfEducation": null,
    legalFullName: {
      firstName: null,
      middleName: null,
      lastName: null,
      suffix: "",
    },
    maritalStatus: null,
    domesticRelationshipInfo: {
      domesticRelationshipIndicator: false,
      domesticRelationshipType: null,
      domesticRelationshipTypeOtherDescription: null,
      domesticRelationshipStateCode: null,
    },
    hasMilitaryService: false,
    militaryService: {
      currentlyServing: false,
      projectedExpirationDate: null,
      currentlyRetired: false,
      nonActivated: false,
      survivingSpouse: false,
    },
    homePhoneNumber: null,
    workPhoneNumber: null,
    workPhoneNumberExtension: null,
    mobilePhoneNumber: null,
    socialSecurityNumber: null,
  },
  supplementalConsumerInfo: {
    homeownershipEducation : {
      completed: false,
      counselingFormat: null,
      housingCounselingAgencyId: null,
      housingEducationProgramName: null,
      dateOfCompletion: null
    },
    housingCounseling : {
      completed: false,
      counselingFormat: null,
      housingCounselingAgencyId: null,
      housingCounselingAgencyName: null,
      dateOfCompletion: null
    },
    languagePreference : {
      language: null,
      languageOtherText: null
    }
  },
  currentAddress: {
    fullAddress: null,
    street: null,
    unit: null,
    city: null,
    stateOrProvince: null,
    postalCode: null,
    country: null,
    monthsAtAddress: null,
    residencyBasis: null,
    monthlyExpense: null,
  },
  hasSeparateMailingAddress: false,
  mailingAddress: {
    fullAddress: null,
    street: null,
    unit: null,
    city: null,
    stateOrProvince: null,
    postalCode: null,
    country: null,
  },
  formerAddresses: [
    // {
    //   "fullAddress": null,
    //   "street": null,
    //   "unit": null,
    //   "city": null,
    //   "stateOrProvince": null,
    //   "postalCode": null,
    //   "country": null,
    //   "monthsAtAddress": null,
    //   "residencyBasis": null,
    //   "monthlyExpense": null
    // }
  ],
  hasDependents: false,
  ageOfDependents: null,
  employmentInfo: [
    // {
    //   "name": null,
    //   "address": null,
    //   "phoneNumber": null,
    //   "title": null,
    //   "startDate": null,
    //   "isCurrent": null,
    //   "isEmployerPartyToLoan": null,
    //   "experienceYears": null,
    //   "experienceMonths": null,
    //   "endDate": null,
    //   "selfEmployed": null,
    //   "atLeast25PctOwnership": null,
    //   "monthlyIncomeOrLoss": null,
    //   "monthlyIncome": null
    // }
  ],
  truvData: [],
  otherIncome: [
    // {
    //   "name": null,
    //   "amount": null,
    //   "type": null
    // }
  ],
  declarations: {
    isPrimaryResidence: null,
    ownershipPropertyType: null,
    ownershipPropertyTitle: null,
    undisclosedFundingAmount: null,
    undisclosedFundingText: null,
    isCosignerOnUndisclosedDebt: null,
    cosignerOnUndisclosedDebtText: null,
    outstandingJudgmentsText: null,
    isDelinquentOnDebt: null,
    delinquentOnDebtText: null,
    isPartyToLawsuit: null,
    partyToLawsuitText: null,
    foreclosedPropertyText: null,
    bankruptcyTypes: null,
    declaredBankruptcyText: null,
    hadOwnership: null,
    hasRelationshipWithSeller: null,
    hasUndisclosedFunding: null,
    hasUndisclosedLoans: null,
    hasUndisclosedCredit: null,
    hasPriorityLien: null,
    hasOutstandingJudgments: null,
    hasConveyedTitle: null,
    hasCompletedShortSale: null,
    hasForeclosuredProperty: null,
    hasDeclaredBankruptcy: null,
  },
  demographics: {
    hasApplicationTakenInPerson: false,
    visualObservation: {
      ethnicity: false,
      sex: false,
      false: false,
    },
    ethnicity: {
      hispanicOrLatino: false,
      hispanicOriginTypes: [],
      hispanicOriginOtherDetail: null,
      dnp: false,
    },
    race: {
      americanIndian: false,
      tribeName: null,
      asian: false,
      asianRaceDesignationTypes: [],
      asianRaceOtherDetail: null,
      africanAmerican: false,
      pacificIslander: false,
      pacificIslanderRaceDesignationTypes: [],
      pacificIslanderRaceOtherDetail: null,
      white: false,
      dnp: false,
    },
    sex: null,
  },
};

export const otherLoansObject = {
  creditorName: "", // "Countrywide",
  lienType: "", // "first",
  fundsSourceType: "", // "builder",
  monthlyPayment: 0, //1000,
  loanAmount: 0, // 50000,
  creditLimit: 0, // 50000
  isCommunityLending: false
};

export const giftGrantsObject = {
  type: "", // "cash",
  deposited: false,
  source: "", // "relative",
  value: 0, // 5000
};

// export const initialSavedFormValues = {
//     "id": "", // UUID
//     "borrower": {
//         ..._.cloneDeep( borrowerObject )
//     },
//     "coBorrowers": [
//         // ...borrowerObject
//     ],
//     "loanInfo": {
//         "loanAmount": 0, // 300000,
//         "purchasePrice": 0,
//         "downPaymentPercentage" : 0,
//         "downPaymentDollar" : 0,
//         "loanPurpose": "", // "purchase",
//         "loanType": "",
//         "propertyType": "", // I ADDED!
//         "propertyAddress": {
//             ..._.cloneDeep(addressObject)
//         },
//         "numberOfUnits": 0,
//         "propertyValue": 0, // 400000,
//         "occupancy": "", // "primary",
//         "isMixedUse": false,
//         "isManufacturedHome": false,
//         "otherLoans": [
//             // ...otherLoansObject,
//             // ...otherLoansObject,
//             // ...otherLoansObject,
//         ],
//         "monthlyRentalIncome": 0, // 1000,
//         "giftsAndGrants": [
//             // ...giftGrantsObject,
//             // ...giftGrantsObject,
//             // ...giftGrantsObject,
//         ]
//     },
//     "realEstate": [
//         // ...realEstateObject
//     ],
//     "assets": {
//         "accounts": [
//             // ...assetsAccountObject,
//             // ...assetsAccountObject,
//             // ...assetsAccountObject,
//         ],
//         "other": [
//             // ...assetsOtherObject,
//             // ...assetsOtherObject,
//             // ...assetsOtherObject,
//          ]
//     },
//     "liabilities": {
//         "accounts": [
//             // ...liabilityAccountObject,
//             // ...liabilityAccountObject,
//             // ...liabilityAccountObject,
//         ],
//         "other": [
//             // ...liabilityOtherObject,
//             // ...liabilityOtherObject,
//             // ...liabilityOtherObject,
//          ]
//     },
// }

// full json object

export const skeleton = {
  id: "3p4YRrKHxoShifzxZfEp",
  borrower: {
    personalInfo: {
      alternateNames: [
        // {
        //   "firstName": null,
        //   "middleName": null,
        //   "lastName": null,
        //   "suffix": ""
        // }
      ],
      dateOfBirth: null,
      citizenship: null,
      email: null,
      // "highestLevelOfEducation": null,
      legalFullName: {
        firstName: null,
        middleName: null,
        lastName: null,
        suffix: "",
      },
      maritalStatus: null,
      domesticRelationshipInfo: {
        domesticRelationshipIndicator: false,
        domesticRelationshipType: null,
        domesticRelationshipTypeOtherDescription: null,
        domesticRelationshipStateCode: null,
      },
      hasMilitaryService: false,
      militaryService: {
        currentlyServing: false,
        projectedExpirationDate: null,
        currentlyRetired: false,
        nonActivated: false,
        survivingSpouse: false,
      },
      homePhoneNumber: null,
      workPhoneNumber: null,
      workPhoneNumberExtension: null,
      mobilePhoneNumber: null,
      socialSecurityNumber: null,
    },
    supplementalConsumerInfo: {
      homeownershipEducation : {
        completed: false,
        counselingFormat: null,
        housingCounselingAgencyId: null,
        housingEducationProgramName: null,
        dateOfCompletion: null
      },
      housingCounseling : {
        completed: false,
        counselingFormat: null,
        housingCounselingAgencyId: null,
        housingCounselingAgencyName: null,
        dateOfCompletion: null
      },
      languagePreference : {
        language: null,
        languageOtherText: null
      }
    },
    currentAddress: {
      // "fullAddress": null,
      street: null,
      unit: null,
      city: null,
      stateOrProvince: null,
      postalCode: null,
      country: null,
      monthsAtAddress: null,
      residencyBasis: null,
      monthlyExpense: null,
    },
    hasSeparateMailingAddress: false,
    mailingAddress: {
      // "fullAddress": null,
      street: null,
      unit: null,
      city: null,
      stateOrProvince: null,
      postalCode: null,
      country: null,
    },
    formerAddresses: [
      // {
      //   "fullAddress": null,
      //   "street": null,
      //   "unit": null,
      //   "city": null,
      //   "stateOrProvince": null,
      //   "postalCode": null,
      //   "country": null,
      //   "monthsAtAddress": null,
      //   "residencyBasis": null,
      //   "monthlyExpense": null
      // }
    ],
    hasDependents: false,
    ageOfDependents: null,
    employmentInfo: [
      // {
      //   "name": null,
      //   "address": null,
      //   "phoneNumber": null,
      //   "title": null,
      //   "startDate": null,
      //   "isCurrent": false,
      //   "isEmployerPartyToLoan": false,
      //   "experienceYears": null,
      //   "experienceMonths": null,
      //   "endDate": null,
      //   "selfEmployed": false,
      //   "atLeast25PctOwnership": false,
      //   "monthlyIncomeOrLoss": null,
      //   "monthlyIncome": null
      // }
    ],
    otherIncome: [
      // {
      //   "name": null,
      //   "amount": null,
      //   "type": null
      // }
    ],
    declarations: {
      isPrimaryResidence: null,
      ownershipPropertyType: null,
      ownershipPropertyTitle: null,
      undisclosedFundingAmount: null,
      undisclosedFundingText: null,
      isCosignerOnUndisclosedDebt: null,
      cosignerOnUndisclosedDebtText: null,
      outstandingJudgmentsText: null,
      isDelinquentOnDebt: null,
      delinquentOnDebtText: null,
      isPartyToLawsuit: null,
      partyToLawsuitText: null,
      foreclosedPropertyText: null,
      bankruptcyTypes: null,
      declaredBankruptcyText: null,
      hadOwnership: null,
      hasRelationshipWithSeller: null,
      hasUndisclosedFunding: null,
      hasUndisclosedLoans: null,
      hasUndisclosedCredit: null,
      hasPriorityLien: null,
      hasOutstandingJudgments: null,
      hasConveyedTitle: null,
      hasCompletedShortSale: null,
      hasForeclosuredProperty: null,
      hasDeclaredBankruptcy: null,
    },
    demographics: {
      hasApplicationTakenInPerson: false,
      visualObservation: {
        ethnicity: false,
        sex: false,
        false: false,
      },
      ethnicity: {
        hispanicOrLatino: false,
        hispanicOriginTypes: [],
        hispanicOriginOtherDetail: null,
        dnp: false,
      },
      race: {
        americanIndian: false,
        tribeName: null,
        asian: false,
        asianRaceDesignationTypes: [],
        asianRaceOtherDetail: null,
        africanAmerican: false,
        pacificIslander: false,
        pacificIslanderRaceDesignationTypes: [],
        pacificIslanderRaceOtherDetail: null,
        white: false,
        dnp: false,
      },
      sex: null,
    },
  },
  coborrowers: [
    // {
    //   "id": null,
    //   "personalInfo": null,
    //   "currentAddress": null,
    //   "hasSeparateMailingAddress": false,
    //   "mailingAddress": null,
    //   "formerAddresses": null,
    //   "hasDependents": false,
    //   "ageOfDependents": null,
    //   "employmentInfo": null,
    //   "otherIncome": null,
    //   "declarations": null,
    //   "demographics": null
    // }
  ],
  loanInfo: {
    loanAmount: null,
    loanPurpose: null,
    loanOtherPurpose: null,
    loanPurposeOtherText: null,
    monthlyRentalIncome: null,
    numberOfUnits: null,
    attachmentType: null,
    propertyValue: null,
    occupancy: null,
    mixedUse: false,
    manufacturedHome: false,

    // "propertyAddress": {
    //   "street": null,
    //   "unit": null,
    //   "city": null,
    //   "stateOrProvince": null,
    //   "postalCode": null,
    //   "country": null
    // },
    otherLoans: [
      // {
      //   "creditorName": null,
      //   "monthlyPayment": null,
      //   "creditLimit": null,
      //   "lienType": null,
      //   "loanAmount": null
      // }
    ],
    giftsAndGrants: [
      // {
      //   "type": null,
      //   "deposited": false,
      //   "source": null,
      //   "value": null
      // }
    ],
  },
  assets: [
    // {
    //   "category": "account",
    //   "type": "checking",
    //   "institutionName": "Treasury CU",
    //   "accountNumber": "554466",
    //   "value": 9000
    // },
    // {
    //   "category": "other",
    //   "type": "money_market",
    //   "name": "Treasury CU",
    //   "value": 100000
    // },
  ],
  liabilities: [
    // {
    //   "category": "account",
    //   "name": null,
    //   "monthlyPayment": null,
    //   "institutionName": null,
    //   "type": "installment",
    //   "accountNumber": null,
    //   "balance": null,
    //   "isToBePaidOff": false
    // },
    // {
    //   "category": "other",
    //   "name": null,
    //   "monthlyPayment": null,
    //   "type": "child_support"
    // }
  ],
  realEstate: [
    // {
    //   "address": {
    //     "fullAddress": null,
    //     "street": null,
    //     "unit": null,
    //     "city": null,
    //     "stateOrProvince": null,
    //     "postalCode": null,
    //     "country": null
    //   },
    //   "propertyValue": null,
    //   "status": null,
    //   "monthlyRentalIncome": null,
    //   "netMonthlyRentalIncome": null,
    //   "propertyLoans": [
    //     {
    //       "creditorName": null,
    //       "monthlyPayment": null,
    //       "creditLimit": null,
    //       "accountNumber": null,
    //       "type": null,
    //       "balance": null,
    //       "toBePaidOff": false
    //     }
    //   ],
    //   "monthlyExpenses": null,
    //   "intendedOccupancy" : null
    // }
  ],
  lenderLoanInfo: {
    isLivingInACommunityPropertyState: false,
    isPropertyInACommunityPropertyState: false,
    projectType: "property_not_in_a_project",
    projectDesignType: null,
    transactionDetail: {
      isConversionOfContractForDeedOrLandContract: false,
      isRenovation: false,
      isConstructionConversionOrConstructionToPermanent: false,
      closingType: null,
      lotAcquired: null,
      originalCostOfLot: null,
      constructionOrImprovementCosts: null,
    },
    isRefinance: false,
    refinanceType: null,
    refinanceProgram: null,
    refinanceProgramOtherText: null,
    isMortgageUsedToFinanceEnergyRelatedImprovements: false,
    isCleanEnergyLienPaidForThroughPropertyTaxes: false,
    newTitle: null,
    currentTitle: null,
    estateType: null,
    leaseholdExpiration: null,
    titleOwnershipType: null,
    trustType: null,
    indianCountryTrustType: null,
    mortgageType: null,
    mortgageTypeOtherText: null,
    loanFeatures: {
      isBalloon: false,
      balloonTerm: null,
      isInterestOnly: false,
      interestOnlyTerm: null,
      isNegativeAmortization: false,
      isPrepaymentPenalty: false,
      prepaymentPenaltyTerm: null,
      isTemporaryInterestRateBuydown: false,
      initialBuydownRate: null,
      isOther: false,
      otherText: null,
    },
    proposedMonthlyPayment: {
      firstMortgage: null,
      subordinateLiens: null,
      homeownersInsurance: null,
      supplementalPropertyInsurance: null,
      propertyTaxes: null,
      mortgageInsurance: null,
      associationOrProjectDues: null,
      other: null,
      total: null,
    },
  },
};
