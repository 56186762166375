import React, { useReducer } from 'react'
import { GlobalContext } from './GlobalContext'
import { MortgageCalcContext } from './MortgageCalcContext'

import { initialGlobalValues } from './InitialStates/InitialGlobalValues'
import globalReducer from './Reducers/globalReducer'

import { initialMortgageCalcValues } from './InitialStates/InitialMortgageCalcValues'
import mortgageCalcReducer from './Reducers/MortgageCalcReducer'

// import { InitialValues } from './Reducers/CombineReducers/InitialValues';

// import { rootReducer } from './Reducers/CombineReducers/CombineReducers'

function StateStore(props){
  const [ globalValues, globalDispatch ] = useReducer( globalReducer, initialGlobalValues )
  const [ mortgageCalcValues, calcDispatch ] = useReducer( mortgageCalcReducer, initialMortgageCalcValues )

  return (
      <GlobalContext.Provider value={ { globalValues, globalDispatch } }>
          <MortgageCalcContext.Provider value={ {mortgageCalcValues, calcDispatch } }>
            {props.children}
          </MortgageCalcContext.Provider>
      </GlobalContext.Provider>
  )
}
export default StateStore