export const initialTenOhThreeValues = {
  authToken: "",
  refRoute: "",
  loanId: "",
  forceCompleted: false,
  sessionTimedOut: false,
  mobileMenuViz: false,
  initialized: false,
  userInfo: {},
  config: {},
  numberOfBorrowers: 1,
  userType: "",
  urlPrefix: "",
  urlQueryParams: "",
  templateUID: "",
  currentPath: "",
  // combinedAssetList : [],
  currentAsset: {},
  // combinedLiabilityList : [],
  currentLiability: {},
  currentRealEstate: {},
  currentEmployment: {},
  currentOtherIncome: {},
  currentGift: {},
  requiredErrors: [],
  employmentHistoryConfirmed: false,
  // noRealEstate : false,
  generateDiff: false,
  generateCustomDataDiff: false,
  finalReviewRedirect: null,
  showFinalCompleteModal: false,
  showErrorModal: false,
  showLogoutErrorModal: false,
  showPatchErrorModal: false,
  showVerifyIncomeLink: {},
  errorMessage: "",
  customData: {},
  filteredRoutes: [],
  loadingScreenVisible: false,
  loadingScreenMessage: "",
  readOnly: false,
  losLoanNumberSet: false,
  lenderEmail: "",
  subtitleText: "en-US",
  showSubtitles: false,
  userView: "",
  coboDataBackup: {},
  lenderActiveLink: "",
  lenderLayer: "",
  downloadLinks: null,
  an2Output: false,
  addAddressClick: false,
  teamLogo: "",
  borrowerEConsentSaved: false,
  borrowerCreditConsentSaved: false,
  coBorrowerEConsentSaved: false,
  coBorrowerCreditConsentSaved: false,
  saveIndicator: "",
  isUnderContract: false,
};

export const customData = {
  buyingState: null,
  noAssets: false,
  noLiabilities: false,
  noRealEstate: false,
  noGiftsGrants: false,
  welcomeComplete: false,
  assetsComplete: false, // TODO: move to top level
  liabilitiesComplete: false, // TODO: move to top level
  realEstateComplete: false, // TODO: move to top level
  loanInfoComplete: false, // TODO: move to top level
  reviewSubmitComplete: false, // TODO: move to top level
  voaAccounts: [],
  workingWithRealtor: null,
  realtorPermission: false,
  realtorName: null,
  realtorEmail: null,
  mclBorrowerConsent: false,
  plaidAccessTokens: [],
  // textMessageAlerts: false,
  an2Data: {
    downPayment: null,
    purchasePrice: null,
    typeOfProperty: "",
    refiIsCurrentAddress: false,
    refiExistingLiens: null,
    refiOriginalCost: null, // market value
    refiReason: "",
    refiYearBuilt: null, // year acquired
    assets: [],
    liabilities: [],
    realEstate: [],
  },
  borrowers: [
    {
      coBorrower: false,
      borrowerComplete: false,
      creditCheckConsent: false,
      hasAcceptedEConsent: false,
      tcpaConsent: false,
      note: false,
      isUnemployedTimeConfirmed: false,
      livesWithBorrower: false,
      sectionComplete: false,
      noOtherIncome: false,
      noEmployment: false,
      nickName: null,
      highestLevelEducation: null,
      otherCostsPerMonth: null,
      rentalIncomePerMonth: null,
      formFreeConsent: false,
      declarations: {
        declarationsIntendPrimary: false,
        declarationsJudgementAgainst: false,
        declarationsJudgementAgainstText: "",
        declarationsDeclaredBankrupt: false,
        declarationsDeclaredBankruptText: "",
        declarationsForeclosed: false,
        declarationsForeclosedText: "",
        declarationsPartyLawsuit: false,
        declarationsPartyLawsuitText: "",
        declarationsDirectIndirectLoan: false,
        declarationsDirectIndirectLoanText: "",
        declarationsDelinquent: false,
        declarationsDelinquentText: "",
        declarationsAlimony: false,
        declarationsAlimonyText: "",
        declarationsBorrowed: false,
        declarationsBorrowedText: "",
        declarationsCoMakerNote: false,
        declarationsCoMakerNoteText: "",
        declarationsOwnershipInterest: false,
        declarationsTypeOfProperty: "",
        declarationsHoldTitleProperty: "",
      },
    },
  ],
  customFields: {},
};

export const coBorrowerCustomData = {
  coBorrower: true,
  borrowerComplete: false,
  creditCheckConsent: false,
  hasAcceptedEConsent: false,
  note: false,
  isUnemployedTimeConfirmed: false,
  livesWithBorrower: false,
  sectionComplete: false,
  noOtherIncome: false,
  noEmployment: false,
  nickName: null,
  highestLevelEducation: null,
  otherCostsPerMonth: null,
  rentalIncomePerMonth: null,
  formFreeConsent: false,
  declarations: {
    declarationsIntendPrimary: false,
    declarationsJudgementAgainst: false,
    declarationsJudgementAgainstText: "",
    declarationsDeclaredBankrupt: false,
    declarationsDeclaredBankruptText: "",
    declarationsForeclosed: false,
    declarationsForeclosedText: "",
    declarationsPartyLawsuit: false,
    declarationsPartyLawsuitText: "",
    declarationsDirectIndirectLoan: false,
    declarationsDirectIndirectLoanText: "",
    declarationsDelinquent: false,
    declarationsDelinquentText: "",
    declarationsAlimony: false,
    declarationsAlimonyText: "",
    declarationsBorrowed: false,
    declarationsBorrowedText: "",
    declarationsCoMakerNote: false,
    declarationsCoMakerNoteText: "",
    declarationsOwnershipInterest: false,
    declarationsTypeOfProperty: "",
    declarationsHoldTitleProperty: "",
  },
};
