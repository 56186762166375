import { initialMortgageCalcValues } from '../InitialStates/InitialMortgageCalcValues'

const mortgageCalcReducer = ( state = initialMortgageCalcValues, action ) => {
  switch( action.type ) {
////// MortgageCalculator
      case 'UPDATE_HOME_PRICE':
        let updateHomePrice = state.mortgageCalc.map((field) => {
          if (field.name === action.payload.name) {
            field.value = action.payload.value
            field.edited = true;
          }
          return field
        })
        return {
            ...state,
            mortgageCalc: [
              ...updateHomePrice
            ]
        }
      case 'UPDATE_LOAN_AMOUNT':
        let updateLoanAmount = state.mortgageCalc.map((field) => {
          if (field.name === action.payload.name) {
            field.value = action.payload.value
            field.edited = true;
          }
          return field
        })
        return {
            ...state,
            mortgageCalc: [
              ...updateLoanAmount
            ]
        }
      case 'UPDATE_DOWN_PAYMENT':
        let updateDownPayment = state.mortgageCalc.map((field) => {
          if (field.name === action.payload.name) {
            field.value = action.payload.value
            field.edited = true;
          }
          return field
        })
        return {
            ...state,
            mortgageCalc: [
              ...updateDownPayment
            ]
        }
      case 'UPDATE_DOWN_PAYMENT_PERCENT':
        let updateDownPaymentPercent = state.mortgageCalc.map((field) => {
          if (field.name === action.payload.name) {
            field.value = action.payload.value
            field.edited = true;
          }
          return field
        })
        return {
            ...state,
            mortgageCalc: [
              ...updateDownPaymentPercent
            ]
        }
      default:
          return state
  }
}

export default mortgageCalcReducer