export const initialGlobalValues = {
  userValues: {
    email: '',
    legalFirstName: '',
    legalLastName: '',
    phoneNumber: '',
    workingWithRealtor: false,
    realtorName: '',
    realtorEmail: '',
    propertyState: '',
    propertyCounty: '',
    propertyCountyOptions: []
  },
  landingPageLink: '',
  applyNowColorScheme: '',
  customizationMode: false,
  customizations:{},
  customizationChangeLog : [],
  blur : false,
  mobileModalViz : false,
  mobileModalContent : null,
  hasOpenModal : false,
  showMobileLoginModal : false,
  noHeaderFooter : false,
  landingPageParams : '',
  teamLogo : '',
  showProductNames: false,
  propertyTax: 0,
  homeownersInsurance: 0,
  hoaFees: 0,
  previousLoginType: '',
}
