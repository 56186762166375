import { initialGlobalValues } from '../InitialStates/InitialGlobalValues'

const globalReducer = ( state = initialGlobalValues, action ) => {
  switch( action.type ) {

      case 'UPDATE_EMAIL':
        return {
            ...state,
            userValues: {
              ...state.userValues,
              email: action.payload
            }
        }
      case 'UPDATE_NAME':
        return {
            ...state,
            userValues: {
              ...state.userValues,
              legalFirstName: action.payload
            }
        }
      case 'UPDATE_LAST_NAME':
        return {
            ...state,
            userValues: {
              ...state.userValues,
              legalLastName: action.payload
            }
        }

      case 'UPDATE_PHONE':
        return {
            ...state,
            userValues: {
              ...state.userValues,
              phoneNumber: action.payload
            }
        }
      case 'UPDATE_PROPERTY_STATE':
        return {
            ...state,
            userValues: {
              ...state.userValues,
              propertyState: action.payload
            }
        }
      case 'UPDATE_PROPERTY_COUNTY':
        return {
            ...state,
            userValues: {
              ...state.userValues,
              propertyCounty: action.payload
            }
        }
      case 'UPDATE_PROPERTY_COUNTY_OPTIONS':
        return {
            ...state,
            userValues: {
              ...state.userValues,
              propertyCountyOptions: action.payload
            }
        }
      case 'UPDATE_REALTOR_WORKING_WITH':
        return {
          ...state,
          userValues: {
            ...state.userValues,
            workingWithRealtor: action.payload
          }
        }

      case 'UPDATE_REALTOR_NAME':
        return {
          ...state,
          userValues: {
            ...state.userValues,
            realtorName: action.payload
          }
        }

      case 'UPDATE_REALTOR_EMAIL':
        return {
          ...state,
          userValues: {
            ...state.userValues,
            realtorEmail: action.payload
          }
        }
      case 'UPDATE_APPLY_NOW_COLOR':
        return {
          ...state,
          applyNowColorScheme: action.payload
        }

      case 'UPDATE_CUSTOMIZATIONS':
        return {
          ...state,
          customizations : {
            // ...state.customizations,
            ...action.payload
          }
        }

      case 'UPDATE_COMPANY_SSO_PROVIDERS':
        return {
          ...state,
          companySsoProviders: action.payload
        }

      case 'UPDATE_EDIT_MODE':
        return {
          ...state,
          customizationMode: action.payload
        }

      case 'UPDATE_CUSTOMIZATION_CHANGE_LOG':
        let updated = state.customizationChangeLog.map( i => {return i})
        updated.push( action.payload )
        return {
          ...state,
          customizationChangeLog : updated
        }

      case 'CLEAR_CUSTOMIZATION_CHANGE_LOG':
        return {
          ...state,
          customizationChangeLog : []
        }
      case 'SET_BLUR':
        return {
          ...state,
          blur : action.payload
        }
      case 'SET_MOBILE_MODAL_VIZ':
        return {
          ...state,
          mobileModalViz : action.payload
        }
      case 'SET_HAS_OPEN_MODAL':
        return {
          ...state,
          hasOpenModal : action.payload
        }
      case 'SET_MOBILE_MODAL_CONTENT' :
        return {
          ...state,
          mobileModalContent : action.payload
        }
      case 'SET_SHOW_PRODUCT_NAMES':
        return {
          ...state,
          showProductNames: action.payload
        }
      case 'SET_PROPERTY_TAX':
          return {
            ...state,
            propertyTax: action.payload
          }
      case 'SET_HOMEOWNERS_INSURANCE':
          return {
            ...state,
            homeownersInsurance: action.payload
          }
      case 'SET_HOA_FEES':
          return {
            ...state,
            hoaFees: action.payload
          }
      case 'SET_SHOW_MOBILE_LOGIN_MODAL':
        return {
          ...state,
          showMobileLoginModal : action.payload
        }
      case 'SET_NO_HEADER_FOOTER' :
        return {
          ...state,
          noHeaderFooter : action.payload
        }
      case 'SET_TEAM_LOGO' :
        return {
          ...state,
          teamLogo : action.payload
        }
      case 'SET_LANDING_PAGE_PARAMS' :
        return {
          ...state,
          landingPageParams : action.payload
        }
      case 'SET_LANDING_PAGE_LINK' :
        return {
          ...state,
          landingPageLink : action.payload
        }
      case 'SET_PREVIOUS_LOGIN_TYPE' :
        return {
          ...state,
          previousLoginType : action.payload
        }
      default:
          return state
  }
}

export default globalReducer